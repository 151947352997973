import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ReceivalRecord, ReceivalCreateRequest } from '../../@core/models/receival-models';
import API from '@aws-amplify/api';
import { Observable } from 'rxjs';
import 'rxjs/add/observable/of';
@Injectable()
export class ReceivalApi {

    apiWrite = '/inventory/Receival/write';
    apiQuery = '/inventory/Receival/query';

    constructor(
        private http:HttpClient,
        
        ){}

    
    search(query:any) {
        //return this.http.get<ReceivalRecord[]>(`${environment.apiUrl}/receival/Receival`);
        let myInit = {
            response: true,
            body:  {
                data: {
                    attributes: {},
                    sort: {},
                    limit: {}
                }
            }
        }
        return Observable.of(API.post(environment.apiName, this.apiQuery, myInit).then(async response => {
            return response.data.data
        }).catch(error => {
            console.log(error.response)
            return error
        })); 
    }

    get(id:string) {
        let myInit = {
            response: true,
            body:  {
                data: {
                    attributes: {
                        PalletId: {
                            type: "equals",
                            value: id || ""
                        }
                    },
                    sort: {},
                    limit: {}
                }
            }
        }
        return Observable.of(API.post(environment.apiName, this.apiQuery, myInit).then(async response => {
            return response.data.data
        }).catch(error => {
            console.log(error.response)
            return error
        }));
    }

    create(request: ReceivalCreateRequest){
        
        let myInit = {
            body:  {
                data: request
            }
        }
        return Observable.of(API.post(environment.apiName, this.apiWrite, myInit).then(response => {
            return response.body
        }).catch(error => {
            console.log(error.response)
            return error
        })); 
        
    }

    update(id: string, request: any){
        
    }

    delete(id: string){
        
    }

    
}