import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemeModule } from '../../@theme/theme.module';
import { PackingReceivalComponent } from './packing-receival/packing-receival.component';
import { PackingReceivalPlaceholderComponent } from './packing-receival-placeholder/packing-receival-placeholder.component';
import { PackingService } from './packing.service';
import { PackingComponent } from './packing.component';
import { PackingFormComponent } from './packing-form/packing-form.component';
import { NbWindowModule, NbInputModule, NbStepperModule, NbSelectModule, NbCheckboxModule } from '@nebular/theme';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import {PackagingFormComponent} from '../packaging/packaging-form/packaging-form.component';
import {ReceivalFormComponent} from '../receival/receival-form/receival-form.component'
import {DispatchFormComponent} from '../dispatch/dispatch-form/dispatch-form.component'
import {SalesFormComponent} from '../sales/sales-form/sales-form.component'
@NgModule({
  imports: [
    ThemeModule,
    NbWindowModule,
    NbInputModule,
    NbStepperModule,
    NbSelectModule,
    NbCheckboxModule,
    CommonModule,
    NgxChartsModule,
  ],
  declarations: [
    PackingComponent,
    PackingReceivalComponent,
    PackingReceivalPlaceholderComponent,
    PackingFormComponent,
    PackagingFormComponent,
    ReceivalFormComponent,
    DispatchFormComponent,
    SalesFormComponent

  ],
  providers: [
    PackingService
  ],
  exports: [
    PackingFormComponent,
    PackagingFormComponent,
    ReceivalFormComponent,
    DispatchFormComponent,
    SalesFormComponent
  ],
  
})

export class PackingModule { }