import { Component, ViewChild, Output, OnInit, OnDestroy } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { Ng2SmartTableComponent } from 'ng2-smart-table/ng2-smart-table.component';
import { EventEmitter } from 'events';
import { Subscription } from 'rxjs';
import { DispatchService } from './dispatch.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { NbDialogService } from '@nebular/theme';
import { PackhouseService } from '../packhouse/packhouse.service';

@Component({
  selector: 'ngx-dispatch',
  templateUrl: './dispatch.component.html',
  styles: [`
    nb-card {
      transform: translate3d(0, 0, 0);
    }
  `],
})

export class DispatchComponent implements 
  OnInit, 
  OnDestroy 
  {

  /// All these properties setup the Smart Table Component:
  // Let's us catch events if we wire up action buttons such as create etc
  @Output() create: EventEmitter = new EventEmitter();
  // Allows the component source to talk to the dom element ('#table')
  @ViewChild('table') table: Ng2SmartTableComponent;
  // Model for the component
  source: LocalDataSource = new LocalDataSource();
  // UI setup for the component
  settings: {};

  // listener for the data in the service to update the UI when
  // records are loaded or pushed via notification to the client
  dispatchSubscriber: Subscription;
  selectedIndex: number = 1
  constructor(
    private dispatchService: DispatchService,
    private translate: TranslateService,
    private router: Router,
    private datepipe: DatePipe,
    private dialogService: NbDialogService,
    private route: ActivatedRoute,
    private packhouse: PackhouseService
  )
  {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => { 
      this.renderSettings();
    });

    this.dispatchSubscriber = this.dispatchService.dispatchs.subscribe(
      dispatchs => {
        this.source = new LocalDataSource(dispatchs);
      }
    )
    this.renderSettings();

    this.route.url.subscribe(segment => {
      console.log('route changed!');
      const currentStep = segment[segment.length - 1].path;
      if(currentStep) this.packhouse.setCurrentStep(currentStep);
    });
  }

  ngOnInit(){

  }

  ngOnDestroy(){
    this.dispatchSubscriber.unsubscribe();
  }

  pageForward(){
    this.dispatchService.pageForward();
  }

  pageBack(){
    this.dispatchService.pageBack();
  }

  newDispatch(){
    this.router.navigateByUrl("/qualipac/new-dispatch");
    
  }
  rowSelected(palletId:string) {
    console.log('pallet in table selected: ', palletId);
    //this.palletId = palletId
    this.dispatchService.getById(palletId)  

  }

  

  // set up the smart table in the ui with links to the model (columns)
  // and action buttons (add/edit etc)
  // pallet for example - is the name of the model propery for the table row.
  renderSettings(){
    this.settings = Object.assign({}, {
      actions: false,
      columns: {
        /* Generated */
        PalletNumber: {
          title: 'Pallet',
          type: 'number',
          filter: true
        },
        /* Date */
        DispatchDate: {
          title: 'Dispatched',
          filter:true,
          type: 'text'
        },
        /* Lookup */
        DeliveryDate: {
          title: 'Delivered',
          filter:true,
          type: 'text'
        },
        /* Filtered Lookup - TODO: work out how to filter */
        DispatchNote: {
          title: 'Note',
          type:'string',
          filter:true
        },
        /* Lookup */
        FreightCompany: {
          title: 'Freighter',
          type: 'string',
          filter:true
        },
        FreightPayment: {
          title: 'Freight Payer',
          type: 'string',
          filter:true
        },
        Agent: {
          title: 'Agent',
          type: 'string',
          filter:true
        },
        Export: {
          title: 'Export',
          type: 'string',
          filter:true
        },
        Destination: {
          title: 'Destination',
          type: 'string',
          filter:true
        }
      },
      mode: 'external',
      pager: {
        perPage: 20
      }
    });
  }
}