import {Component} from "@angular/core";
import {UserRegistrationService} from "../user-registration.service";
import {CognitoCallback} from "../cognito.service";
import {Router} from "@angular/router";
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'awscognito-angular2-app',
    templateUrl: './resendCode.html'
})

export class ResendCodeComponent implements CognitoCallback {

    email: string;
    errorMessage: string;

    constructor(
        public registrationService: UserRegistrationService, 
        public router: Router,
        public translate: TranslateService
        ) 
    {

    }

    resendCode() {
        this.registrationService.resendCode(this.email, this);
    }

    cognitoCallback(error: any, result: any) {
        if (error != null) {
            this.errorMessage = this.translate.instant('UX.AUTH.REGISTRATION.RESEND.SOMETHINGWENTWRONG');
        } else {
            this.router.navigate(['/auth/confirmRegistration', this.email]);
        }
    }
}