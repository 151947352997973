import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { HomeComponent, HomeLandingComponent } from './home/home.component';
import { AuthGuard } from './@auth/auth.guard';
import { AuthComponent } from './@auth/auth.component';
import { QualipacModule } from './@qualipac/qualipac.module';

const config: ExtraOptions = {
  useHash: true,
};

// const authRoutes: Routes = [
//   {
//     path: '',
//     redirectTo: '/nihon',
//     pathMatch: 'full'
//   },
//   { 
//     path: 'auth', 
//     component: AuthComponent,
//     children: [
//       { path: '', loadChildren: 'app/@auth/auth.module#AuthModule' }
//     ]
//   }
// ];

// const nihonRoutes: Routes = [
//   {
//     path: '',
//     canActivate: [AuthGuard],
//     redirectTo: '/nihon',
//     pathMatch: 'full'
//   },
//   { 
//     path: 'nihon', 
//     component: NihonComponent,
//     children: [
//       {
//         path: '',
//         loadChildren: 'app/@nihon/nihon.module#NihonModule' 
//       }
//     ]
//   }
// ]
  
const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./@auth/auth.module').then(mod => mod.AuthModule)
  },
  {
    path: 'qualipac',
    loadChildren: () => import('./@qualipac/qualipac.module').then(mod => mod.QualipacModule)
  },
  {
    path: '',
    redirectTo: '/auth/login',
    pathMatch: 'full'
  }
];

// const routes: Routes = [
//   {
//       path: '',
//       children: [
//           ...authRoutes,
//           ...nihonRoutes
//       ]
//   },
// ];

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})

export class AppRoutingModule {

}

// const routes: Routes = [
//   { path: '', loadChildren: 'app/@nihon/nihon.module#NihonModule' },
//   {path: 'login', component: LoginComponent},
//   {path: 'register', component: RegisterComponent},
//   {path: 'confirmRegistration/:username', component: RegistrationConfirmationComponent},
//   {path: 'resendCode', component: ResendCodeComponent},
//   {path: 'forgotPassword/:email', component: ForgotPassword2Component},
//   {path: 'forgotPassword', component: ForgotPasswordStep1Component},
//   {path: 'newPassword', component: NewPasswordComponent},
// //{ path: 'authenticate', component: AuthenticateComponent},
//   {
//     path: 'auth',
//     component: NbAuthComponent,
//     children: [
//       {
//         path: '',
//         component: NbLoginComponent,
//       },
//       {
//         path: 'login',
//         component: NbLoginComponent,
//       },
//       {
//         path: 'register',
//         component: NbRegisterComponent,
//       },
//       {
//         path: 'logout',
//         component: NbLogoutComponent,
//       },
//       {
//         path: 'request-password',
//         component: NbRequestPasswordComponent,
//       },
//       {
//         path: 'reset-password',
//         component: NbResetPasswordComponent,
//       },
//     ],
//   },
//   { path: '', redirectTo: 'authenticate', pathMatch: 'full' },
//   { path: '**', redirectTo: 'authenticate' },
// ];