export class Query {
    constructor(init?:Partial<Query>){
        this.attributes = new Object();
        this.sort = new Object();
        if(init) {
            this.size = init.size;
            this.page = init.page;
            this.table_type = init.table_type;
        } else {
            this.size = 10;
            this.page = 1;
        }
    }
    size: number;
    page: number;
    table_type: string;
    attributes: any;
    sort: any;
}

export class PalletQuery extends Query {
    constructor(){
        super();
        this.attributes = new Object();
        this.sort = new Object();
    }
    attributes: any;
    sort: any;
}

export class BoxQuery extends Query {
    constructor() {
        super();
        this.attributes = {};
        this.sort = {};
    }
    attributes: any;
    sort: any;
}

export class JObQuery extends Query {
    constructor() {
        super();
        this.attributes = {};
        this.sort = {};
    }
    attributes: any;
    sort: any;
}