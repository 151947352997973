import * as uuid from 'uuid';

// raw from api
export class SalesRecord {
    constructor(init?:any) {
        if(init){
            this.saleid = init.saleid;
            this.palletid = init.palletid ;
            this.unitprice = init.unitprice;
            this.rejected = init.rejected;
            this.excludedfromgps = init.excludedfromgps;
            this.domesticfreightperunit = init.domesticfreightperunit;
            this.exportfreightperunit = init.exportfreightperunit;
            this.commission = init.commission;
            this.promocharge = init.promocharge
        }
    }
    saleid: string;
    palletid: string;
    unitprice: string;
    rejected: string;
    excludedfromgps: string;
    domesticfreightperunit: string;
    exportfreightperunit: string;
    commission: string;
    promocharge: string;
}

// use this in the view
export class SalesViewModel {
    constructor(init?:any) {
        if(init){
            this.saleid = init.saleid;
            this.palletid = init.palletid ;
            this.unitprice = init.unitprice;
            this.rejected = init.rejected;
            this.excludedfromgps = init.excludedfromgps;
            this.domesticfreightperunit = init.domesticfreightperunit;
            this.exportfreightperunit = init.exportfreightperunit;
            this.commission = init.commission;
            this.promocharge = init.promocharge
        }
    }
    saleid: string;
    palletid: string;
    unitprice: string;
    rejected: string;
    excludedfromgps: string;
    domesticfreightperunit: string;
    exportfreightperunit: string;
    commission: string;
    promocharge: string;
}

// post/put to the server
// If it's an update, it will have a PalletId, other wise its a create
// so we generate the PalletId client side
// NB: When we have an API that returns the created record, we'll create
// the ids on the API, not the client.
export class SalesCreateRequest {
    constructor(init?:any) {
        if(init){
            this.saleid = init.saleid || uuid.v4();
            this.palletid = init.palletid;
            this.unitprice = init.unitprice || 0;
            this.rejected = init.rejected || 0;
            this.excludedfromgps = init.excludedfromgps || 0;
            this.domesticfreightperunit = init.domesticfreightperunit || 0;
            this.exportfreightperunit = init.exportfreightperunit || 0;
            this.commission = init.commission || 0;
            this.promocharge = init.promocharge || 0;
        }
        else {
            this.saleid = uuid.v4();
        }
    }
    saleid: string;
    palletid: string;
    unitprice: string;
    rejected: string;
    excludedfromgps: string;
    domesticfreightperunit: string;
    exportfreightperunit: string;
    commission: string;
    promocharge: string;
}

// response - not necessary in this version of the API
export class SalesCreateResponse {
    
}