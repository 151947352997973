import * as uuid from 'uuid';

// raw from api
export class DispatchRecord {
    constructor(init?:any) {
        if(init){
            
            this.palletid = init.palletid;
            this.palletnumber = init.palletnumber;
            this.dispatchdate = init.dispatchdate;
            this.deliverydate = init.deliverydate;
            this.dispatchnote = init.dispatchnote;
            this.freightcompany = init.freightcompany;
            this.freightpayment = init.freightpayment;
            this.agent = init.agent;
            this.export = init.export;
            this.destination = init.destination;
        }
    }
    palletid: string;
    palletnumber: string;
    dispatchdate: string;
    deliverydate: string;
    dispatchnote: string;
    freightcompany: string;
    freightpayment: string;
    agent: string;
    export: string;
    destination: string;
}

// use this in the view
export class DispatchViewModel {
    constructor(init?:any) {
        if(init){
            this.dispatchid = init.dispatchid
            this.palletid = init.palletid;
            this.palletnumber = init.palletnumber;
            this.dispatchdate = init.dispatchdate;
            this.deliverydate = init.deliverydate;
            this.dispatchnote = init.dispatchnote;
            this.freightcompany = init.freightcompany;
            this.freightpayment = init.freightpayment;
            this.agent = init.agent;
            this.export = init.export ? "Yes": "No";
            this.destination = init.destination;
        }
    }
    dispatchid: string;
    palletid: string;
    palletnumber: string;
    dispatchdate: string;
    deliverydate: string;
    dispatchnote: string;
    freightcompany: string;
    freightpayment: string;
    agent: string;
    export: string;
    destination: string;

    // i18n-able data
    dispatchdate_label: string;
    deliverydate_label: string;
    freightcompany_label: string;
    freightpayment_label: string;
    agent_label: string;
    destination_label: string;
}

// post/put to the server
// If it's an update, it will have a PalletId, other wise its a create
// so we generate the PalletId client side
// NB: When we have an API that returns the created record, we'll create
// the ids on the API, not the client.
export class DispatchCreateRequest {
    constructor(init?:any) {
        if(init){
            this.dispatchid = init.dispatchid || uuid.v4();
            this.palletid = init.palletid;
            this.palletnumber = init.palletnumber;
            this.dispatchdate = init.dispatchdate;
            this.deliverydate = init.deliverydate;
            this.dispatchnote = init.dispatchnote;
            this.freightcompany = init.freightcompany;
            this.freightpayment = init.freightpayment;
            this.agent = init.agent;
            this.export = init.export;
            this.destination = init.destination;
        }
        else {
            this.dispatchid = uuid.v4();
        }
    }
    dispatchid: string;
    palletid: string;
    palletnumber: string;
    dispatchdate: string;
    deliverydate: string;
    dispatchnote: string;
    freightcompany: string;
    freightpayment: string;
    agent: string;
    export: string;
    destination: string;
}

// response - not necessary in this version of the API
export class DispatchCreateResponse {
    
}