import { NgModule } from '@angular/core';
import { ThemeModule } from '../../@theme/theme.module';
import { NbWindowModule, NbInputModule, NbStepperModule, NbSelectModule, NbCheckboxModule } from '@nebular/theme';
import { DispatchComponent } from './dispatch.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { DispatchService } from '../dispatch/dispatch.service';
import { DateFormatPipe } from '../../@theme/pipes/dateFormat.pipe';
import { DpDatePickerModule } from 'ng2-date-picker';
import { DispatchFormComponent } from './dispatch-form/dispatch-form.component';
import { DispatchApi } from './dispatch.api';
import { DispatchTableComponent } from './dispatch-table/dispatch-table.component';
import { PackingModule} from '../job/packing.module'
@NgModule({
  imports: [
    ThemeModule,
    NbWindowModule,
    NbInputModule,
    NbStepperModule,
    NbSelectModule,
    NbCheckboxModule,
    Ng2SmartTableModule,
    DpDatePickerModule,
    PackingModule
  ],
  declarations: [
    DispatchComponent,
    DispatchTableComponent
  ],
  providers: [
    DispatchService,
    DispatchApi
  ],
  exports: [
    DispatchTableComponent
  ]
})

export class DispatchModule { }