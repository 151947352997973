import { NgModule } from '@angular/core';
import { ThemeModule } from '../../@theme/theme.module';
import { NbWindowModule, NbInputModule, NbStepperModule, NbSelectModule, NbCheckboxModule } from '@nebular/theme';
import { SettlementComponent } from './settlement.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { SettlementService } from '../settlement/settlement.service';
import { DateFormatPipe } from '../../@theme/pipes/dateFormat.pipe';
import { DpDatePickerModule } from 'ng2-date-picker';
import { SettlementFormComponent } from './settlement-form/settlement-form.component';
import { SettlementApi } from './settlement.api';

@NgModule({
  imports: [
    ThemeModule,
    NbWindowModule,
    NbInputModule,
    NbStepperModule,
    NbSelectModule,
    NbCheckboxModule,
    Ng2SmartTableModule,
    DpDatePickerModule
  ],
  declarations: [
    SettlementComponent,
    SettlementFormComponent
  ],
  providers: [
    SettlementService,
    SettlementApi
  ],
  exports: [
    SettlementFormComponent
  ]
})

export class SettlementModule { }