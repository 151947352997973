import { Component, Input } from '@angular/core';
import { PackhouseRecord } from '../packing.service';

@Component({
  selector: 'ngx-packing-receival',
  templateUrl: 'packing-receival.component.html',
  styleUrls: ['packing-receival.component.scss']
})
export class PackingReceivalComponent {

  @Input() record: PackhouseRecord;
}
