import { Component, Input } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'awscognito-mfa',
    templateUrl: './mfa.html'
})
export class MFAComponent {
    @Input() destination: string;
    @Input() onSubmit: (code: string) => void;

    constructor(
        public translate: TranslateService
    ) {
        console.log("MFAComponent constructor");
    }
}