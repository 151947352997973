import { NgModule } from '@angular/core';
import { AuthComponent } from './auth.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/registration.component';
import { RegistrationConfirmationComponent } from './confirm/confirmRegistration.component';
import { ResendCodeComponent } from './resend/resendCode.component';
import { ForgotPassword2Component, ForgotPasswordStep1Component } from './forgot/forgotPassword.component';
import { NewPasswordComponent } from './newpassword/newpassword.component';
import { AuthRoutingModule } from './auth-routing.module';
import { ThemeModule } from '../@theme/theme.module';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService } from './auth.service';
import { UserLoginService } from './user-login.service';
import { CognitoUtil } from './cognito.service';
import { UserRegistrationService } from './user-registration.service';
import { MFAComponent } from './mfa/mfa.component';
import { AmplifyAngularModule } from 'aws-amplify-angular';
import { DatePipe } from '@angular/common';

const AUTH_COMPONENTS = [
    AuthComponent,
    LoginComponent,
    RegisterComponent,
    RegistrationConfirmationComponent,
    ResendCodeComponent,
    ForgotPassword2Component,
    ForgotPasswordStep1Component,
    NewPasswordComponent,
    MFAComponent
];

@NgModule({
  imports: [
    AuthRoutingModule,
    ThemeModule,
    TranslateModule,
    AmplifyAngularModule
  ],
  declarations: [
    ...AUTH_COMPONENTS
  ],
  providers: [
      AuthService,
      UserLoginService,
      CognitoUtil,
      DatePipe,
      UserRegistrationService
  ]
})

export class AuthModule {

}