import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PalletRecord, PalletCreateRequest } from '../../@core/models/pallet-models';
import { environment } from '../../environments/environment';

import API from '@aws-amplify/api';
import { Observable } from 'rxjs';
import 'rxjs/add/observable/of';

@Injectable()
export class PackagingApi {
    apiWrite = '/inventory/Packing/write';
    apiQuery = '/inventory/Packing/query';

    constructor(
        private http:HttpClient
    ){}

    search(query:any) {
        let myInit = {
            response: true,
            body:  {
                data: {
                    attributes: {},
                    sort: {},
                    limit: {}
                }
            }
        }
        return Observable.of(API.post(environment.apiName, this.apiQuery, myInit).then(async response => {
            return response.data.data
        }).catch(error => {
            console.log(error.response)
            return error
        }));         
    }


    get(Palletid:string) {
        let myInit = {
            response: true,
            body:  {
                data: {
                    attributes: {
                        PalletId: {
                            type: "equals",
                            value: Palletid || ""
                        }
                    },
                    sort: {},
                    limit: {}
                }
            }
        }
        return Observable.of(API.post(environment.apiName, this.apiQuery, myInit).then(async response => {
            return response.data.data
        }).catch(error => {
            console.log(error.response)
            return error
        }));
    }

    
    create(request: PalletCreateRequest){
        //return this.http.post(`${environment.apiUrl}/packagings`, request);
        
        let myInit = {
            body:  {
                data: request
            }
        }
        return Observable.of(API.post(environment.apiName, this.apiWrite, myInit).then(response => {
            return response.body
        }).catch(error => {
            console.log(error.response)
            return error
        }));            
    }

    update(id: string, request: any){
        
    }

    delete(id: string){
        
    }
}