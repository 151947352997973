import { Component, OnInit, ViewChild, OnDestroy, EventEmitter, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Ng2SmartTableComponent } from 'ng2-smart-table/ng2-smart-table.component';
import { DispatchCreateRequest } from '../../../@core/models/dispatch-models';
import { DispatchService } from '../dispatch.service';
import { LookupService } from '../../lookup/lookup.service';
import { Router } from '@angular/router';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { LocalDataSource } from 'ng2-smart-table';

@Component({
  selector: 'qualipac-dispatch-table',
  templateUrl: 'dispatch-table.component.html',
  styleUrls: ['dispatch-table.component.scss']
})

export class DispatchTableComponent {

    @Output() rowSelected = new EventEmitter();
    
    /* Bring the magic of a smart table */
    @ViewChild('table') table: Ng2SmartTableComponent;
    source: LocalDataSource = new LocalDataSource();
    
    // UI setup for the component
    settings: {};
    dispatchSubscriber: Subscription;

    constructor(
        public dispatch: DispatchService,
        public lookup: LookupService,
        public translate: TranslateService
    ){
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => { 
            this.renderSettings();
          });
      
        this.dispatchSubscriber = this.dispatch.dispatchs.subscribe(
            dispatches => {
                this.source = new LocalDataSource(dispatches);
            }
        );

        this.renderSettings();
    }

    onSelect(event): void {
        console.log("Selecting dispatch cell");
        //emit to parent
        console.log(event.data)
        this.rowSelected.emit(event.data.PalletId);
    }

    renderSettings(){
        this.settings = Object.assign({}, {
          actions: false,
          columns: {
            /* Generated */
            PalletNumber: {
              title: 'Pallet',
              type: 'number',
              filter: true
            },
            /* Date */
            dispatchDate_label: {
              title: 'Dispatched',
              filter:true,
              type: 'text'
            },
            /* Lookup */
            deliveryDate_label: {
              title: 'Delivered',
              filter:true,
              type: 'text'
            },
            /* Filtered Lookup - TODO: work out how to filter */
            DispatchNote: {
              title: 'Note',
              type:'string',
              filter:true
            },
            /* Lookup */
            freightCompany_label: {
              title: 'Freight Company',
              type: 'string',
              filter:true
            },
            freightPayment_label: {
              title: 'Freight Payment',
              type: 'string',
              filter:true
            },
            agent_label: {
              title: 'Agent',
              type: 'string',
              filter:true
            },
            Export: {
              title: 'Export',
              type: 'string',
              filter:true
            },
            Destination: {
                title: 'Destination',
                type: 'string',
                filter:true
            }
          },
          mode: 'external',
          pager: {
            perPage: 20
          }
        });
    }
}