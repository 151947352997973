import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReceivalService } from '../receival.service';
import { ReceivalCreateRequest } from '../../../@core/models/receival-models';
import { LookupService } from '../../lookup/lookup.service';
import { Router } from '@angular/router';

@Component({
  selector: 'qualipac-receival-form',
  templateUrl: 'receival-form.html',
  styleUrls: ['receival-form.component.scss']
})

export class ReceivalFormComponent implements OnInit {

  receivalForm: FormGroup;
  receivalRecord: ReceivalCreateRequest;

  constructor(
    private fb: FormBuilder,
    public receival: ReceivalService,
    public lookup: LookupService,
    private router: Router
    ) 
  {

  }

  ngOnInit(){
    this.receivalForm = this.fb.group({
      supplierCtrl: '',
      palletCtrl: '',
      dateCtrl: '',
      blockCtrl: '',
      varietyCtrl: '',
      soldCtrl: '',
      packedCtrl: '',
      cutCtrl: '',
      brandCtrl: '',
      sizeCtrl: '',
      packagingCtrl: '',
      unitsCtrl: ''
    });
  }

  onSubmit() {
    this.receivalForm.markAsDirty();
      const testObj = {
        date: this.receivalForm.value.dateCtrl,
        block: this.receivalForm.value.blockCtrl,
        variety: this.receivalForm.value.varietyCtrl,
        supplier: this.receivalForm.value.supplierCtrl
      }
      const newJob = new ReceivalCreateRequest(testObj)
      this.receival.create(newJob);
      this.router.navigateByUrl("/qualipac/receival");
  }
}