import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SettlementRecord, SettlementCreateRequest } from '../../@core/models/settlement-models';
import { environment } from '../../environments/environment';

@Injectable()
export class SettlementApi {

    constructor(
        private http:HttpClient
        ){}

    search(query:any) {
        return this.http.get<SettlementRecord[]>(`${environment.apiUrl}/settlements`);        
    }

    get(id:string) {
        
    }

    create(request: SettlementCreateRequest){
        return this.http.post(`${environment.apiUrl}/settlements`, request);                
    }

    update(id: string, request: any){
        
    }

    delete(id: string){
        
    }
}