import { Component, OnInit } from '@angular/core';
// import { InventoryService } from '../inventory/inventory.service';
import { Query } from '../../@core/models/query-models';

@Component({
  selector: 'qualipac-dashboard',
  templateUrl: './dashboard.component.html',
})

export class DashboardComponent implements
OnInit {

  query: Query;

  //constructor(private inventory:InventoryService) { }

  ngOnInit(){
    // this.query = new Query({page:1, size:20});
    // this.query.attributes = { Pallet_State : "SUPPLYCHAIN.HORT.APPLE.STATE" };
    // this.inventory.getInventory(this.query);
  }
}