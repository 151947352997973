import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { D3AdvancedPieComponent } from '../../../@theme/components/charts/d3-advanced-pie.component';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { LookupService } from '../../lookup/lookup.service';
import { Query } from '../../../@core/models/query-models';

@Component({
  selector: 'qualipac-inventory-piechart',
  template: `
  <div class="row">
    <nb-card class="col-sm-12 col-md-6  col-lg-6 col-xxl-6">
      <nb-card-header>{{ 'UX.DASHBOARD.BYVARIETY' | translate }}</nb-card-header>
      <nb-card-body>
        <ngx-charts-advanced-pie-chart
          #varietyChart
          *ngIf="byVariety.length > 0"
          [scheme]="colorScheme"
          [results]="byVariety"
          >
        </ngx-charts-advanced-pie-chart>
      </nb-card-body>
    </nb-card>
    <nb-card class="col-sm-12 col-md-6  col-lg-6 col-xxl-6">
      <nb-card-header>{{ 'UX.DASHBOARD.BYPACKAGING' | translate }}</nb-card-header>
      <nb-card-body>
        <ngx-charts-advanced-pie-chart
          #packagingChart
          *ngIf="byPackaging.length > 0"
          [scheme]="colorScheme"
          [results]="byPackaging"
          >
        </ngx-charts-advanced-pie-chart>
        </nb-card-body>
    </nb-card>
  </div>
  `,
})
export class QualiapcInventoryPieChartComponent implements 
  OnDestroy,
  OnInit
  {
    colorScheme: any;
    themeSubscription: any;
    byVariety: object[] = [];
    byPackaging: object[] = [];

    @ViewChild('varietyChart') varietyChart: D3AdvancedPieComponent;
    @ViewChild('packagingChart') packagingChart: D3AdvancedPieComponent;

    query: Query;

    constructor(
      private theme: NbThemeService,
      private lookup: LookupService,
      private translate: TranslateService
      ) {
      this.themeSubscription = this.theme.getJsTheme().subscribe(config => {
        const colors: any = config.variables;
        this.colorScheme = {
          domain: [colors.primaryLight, colors.infoLight, colors.successLight, colors.warningLight, colors.dangerLight],
        };
      });

      this.query = new Query({page:1, size:1000});
      this.query.attributes = { Pallet_State : "SUPPLYCHAIN.HORT.APPLE.STATE" };

      this.translate.onLangChange.subscribe((event: LangChangeEvent) => { 
        this.search();
      });
    }

  ngOnInit(){
    this.byVariety = [];
    this.byPackaging = [];

    this.search();
  }

  search(){
    // this.inventory.getInventory(this.query)
    //   .subscribe(response => {
    //   let boxGroup: Array<InventoryViewModel> = [];
    //     (response as []).forEach(x => {
    //         let record = new InventoryViewModel();
    //         record.fromResponse(x);
    //         boxGroup.push(record);
    //     });
    //     this.loadByPackaging(boxGroup);
    //     this.loadByVariety(boxGroup);
    // });
  }

  // loadByVariety(inventory: ViewModel[]) {
    
  //   const varieties = new Set(inventory.map(item => item.Variety));

  //   //traveler.map(item => item.Amount).reduce((prev, next) => prev + next);

  //   //const varieties = inventory.map(item => item.Variety);
  //   // console.log('varieties count: ', varieties.length);
  //   varieties.forEach(variety => {
  //     let boxes_of_variety = {
  //       name: this.translate.instant(variety),
  //       value: 0
  //     };

  //     boxes_of_variety.value = inventory
  //       .filter(pallet => pallet.Variety === variety)
  //       .reduce((total, boxes) => total + boxes.BoxCount, 0);
    
  //       this.byVariety.push(boxes_of_variety);
  //     });
  // }
  
  // loadByPackaging(inventory: InventoryViewModel[]) {
  //   //const forms = inventory.map(item => item.Packaging);
  //   const forms = new Set(inventory.map(item => item.Packaging));

  //   //console.log('packaging count: ', forms.length);
  //   forms.forEach(packaging => {
  //     let boxes_of_packaging = {
  //       name: this.translate.instant(packaging),
  //       value: 0
  //     };

  //     boxes_of_packaging.value = inventory
  //       .filter(pallet => pallet.Packaging === packaging)
  //       .reduce((total, boxes) => total + boxes.BoxCount, 0);
    
  //       this.byPackaging.push(boxes_of_packaging);
  //     });
  // }

  ngOnDestroy(): void {
    this.themeSubscription.unsubscribe();
  }
}