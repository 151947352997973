import { Component } from '@angular/core';
import { PackhouseService } from '../packhouse.service';

@Component({
  selector: 'qualipac-packhouse-form',
  templateUrl: 'packhouse-form.html',
  styleUrls: ['packhouse-form.component.scss']
})

export class PackhouseFormComponent {
  _selectIndex:number;
  constructor(
    packhouse: PackhouseService
  ) 
  {
    packhouse.stepIndexObserver.subscribe(stepIndex => {
      this._selectIndex = stepIndex;
    });
  }
}