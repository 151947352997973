import { ReceivalApi } from './receival.api';
import { Observable, BehaviorSubject } from 'rxjs';
import * as uuid from 'uuid';
import { Query } from '../../@core/models/query-models';
import { Injectable } from '@angular/core';
import { ReceivalViewModel, ReceivalRecord, ReceivalCreateRequest } from '../../@core/models/receival-models';
import { AuthService } from '../../@auth/auth.service';

@Injectable()
export class ReceivalService {

    receivals: Observable<Array<ReceivalViewModel>>;
    private _receivals: BehaviorSubject<Array<ReceivalViewModel>>;

    selectedPackage: Observable<ReceivalViewModel>;
    private _selectedPackage: BehaviorSubject<ReceivalViewModel>;


    private dataStore: {
        receivals: Array<ReceivalViewModel>,
        selectedPackage: ReceivalViewModel
    }
    
    loading: boolean = false;
    query: Query;

    constructor(
        private receivalApi: ReceivalApi,
        private auth: AuthService
        ){

        // This is a pattern I've found really useful, which 
        // is probably like a roll-your-own Redux but just 
        // used for a single aspect (recivals feature)
        this.dataStore = {
            receivals: new Array<ReceivalViewModel>(),
            selectedPackage: new ReceivalViewModel()
        }    

        this._receivals = <BehaviorSubject<ReceivalViewModel[]>>new BehaviorSubject([]);
        this.receivals = this._receivals.asObservable();

        this._selectedPackage = <BehaviorSubject<ReceivalViewModel>>new BehaviorSubject({});
        this.selectedPackage = this._selectedPackage.asObservable();
        //this.query = new Query({page:1, size:200});
        this.search();
    }

    // Check we haven't already got this record then
    // add it to the service's dataset. Convert it 
    // from an api object to a view mode;
    // NB: When we have view models on the api, 
    // the response will come back in the right shape and this will not be necessary
    addReceival(receival: any) {
        console.log(receival)
        console.log("werid = ", this.dataStore.receivals.findIndex(r => r.PalletId === receival.PalletId))
        if(this.dataStore.receivals.findIndex(r => r.PalletId === receival.PalletId) == -1) {
            this.dataStore.receivals.push(
                new ReceivalViewModel(receival)
            );
            console.log("ADDED", this.dataStore.receivals)
        }
        
    }

    // This isnt currently wired up and is dependent
    // on the api passing back totals.
    pageForward(){
        this.query.page += 1;
        this.search();
    }

    // This isnt currently wired up and is dependent
    // on the api passing back totals.
    pageBack(){
        this.query.page -= 1;
        this.search();
    }

    // calling the api for data, parsing, then updating the subscribers
    // this will be triggered on load, search, and on paging
    search() {
        let fault: boolean = false;
        
        this.receivalApi
            .search(this.query)
            .subscribe(
            // This is the on-success callback
            async (receivals) => {
                let boxGroup: Array<ReceivalViewModel> = [];
                (await receivals as []).forEach(receival => {
                    boxGroup.push(new ReceivalViewModel(receival));
                });
                boxGroup.forEach(item => this.dataStore.receivals.push(item));
                this._receivals.next(Object.assign({}, this.dataStore).receivals);

            },
            // If an error happens..
            error => {
                fault = true;
                console.log(error);
            },
            // This always calls on termination 
            // Like the Finally of a try block
            () => {
                // now flush the internal state to the subscribers
                this._receivals.next(Object.assign({}, this.dataStore).receivals);
                console.log(`Receivals loading complete ${ fault ? 'with' : 'without'} fault.`);
            }
        );
    }


    getById(id: string){
        this.receivalApi.get(id).subscribe(async pallet => {
            const palletData = await pallet;
            console.log(palletData)
            this.dataStore.selectedPackage = new ReceivalViewModel(palletData[0])
            this._selectedPackage.next(Object.assign({}, this.dataStore).selectedPackage)
        })
    }

    create(receival: ReceivalCreateRequest) {
        let fault: Boolean = false;
        //receival.User = this.auth.getUser().id;
        this.receivalApi.create(receival)
        .subscribe(
            success => {
                console.log("SUBSCRIBER SUCCESS:",success);
                this.addReceival(receival);
                // this.dataStore.receivals.push(
                //     new ReceivalViewModel(receival)
                // );
            },
            error => {
                console.error(error);
            },
            () => {
                this.loading = false;
                
                this._receivals.next(Object.assign({}, this.dataStore).receivals);
                console.log(`Receival creation complete ${ fault ? 'with' : 'without'} fault.`);
            }
        );
    }

    update() {

    }

    delete() {

    }
}