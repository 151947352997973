import { Component, ViewChild, Output, OnInit, OnDestroy } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { Ng2SmartTableComponent } from 'ng2-smart-table/ng2-smart-table.component';
import { EventEmitter } from 'events';
import { Subscription } from 'rxjs';
import { SalesService } from './sales.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { NbDialogService } from '@nebular/theme';
import { PackhouseService } from '../packhouse/packhouse.service';
@Component({
  selector: 'ngx-sales',
  templateUrl: './sales.component.html',
  styles: [`
    nb-card {
      transform: translate3d(0, 0, 0);
    }
  `],
})

export class SalesComponent implements 
  OnInit, 
  OnDestroy 
  {

  /// All these properties setup the Smart Table Component:
  // Let's us catch events if we wire up action buttons such as create etc
  @Output() create: EventEmitter = new EventEmitter();
  // Allows the component source to talk to the dom element ('#table')
  @ViewChild('table') table: Ng2SmartTableComponent;
  // Model for the component
  source: LocalDataSource = new LocalDataSource();
  // UI setup for the component
  settings: {};

  // listener for the data in the service to update the UI when
  // records are loaded or pushed via notification to the client
  salesSubscriber: Subscription;

  constructor(
    private salesService: SalesService,
    private translate: TranslateService,
    private router: Router,
    private datepipe: DatePipe,
    private route: ActivatedRoute,
    private packhouse: PackhouseService,
    private dialogService: NbDialogService
  )
  {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => { 
      this.renderSettings();
    });
    


    this.salesSubscriber = this.salesService.saless.subscribe(
      saless => {
        this.source = new LocalDataSource(saless);
      }
    )
    this.renderSettings();
    this.route.url.subscribe(segment => {
      console.log('route changed!');
      const currentStep = segment[segment.length - 1].path;
      if(currentStep) this.packhouse.setCurrentStep(currentStep);
    });
  }

  ngOnInit(){

  }

  ngOnDestroy(){
    this.salesSubscriber.unsubscribe();
  }

  pageForward(){
    this.salesService.pageForward();
  }

  pageBack(){
    this.salesService.pageBack();
  }

  newSale(){
    this.router.navigateByUrl("/qualipac/new-sales");
  }

  onSelect(event): void {
    // we'll bounce to a detail page
  }

  // set up the smart table in the ui with links to the model (columns)
  // and action buttons (add/edit etc)
  // pallet for example - is the name of the model propery for the table row.


  // PalletId: string;
  // UnitPrice: string;
  // Rejected: string;
  // ExcludedFromGPS: string;
  // DomesticFreightPerUnit: string;
  // ExportFreightPerUnit: string;
  // Commission: string;
  // PromoCharge: string;


  renderSettings(){
    this.settings = Object.assign({}, {
      actions: false,
      columns: {
        /* Generated */
        PalletNumber: {
          title: 'Pallet',
          type: 'number',
          filter: true
        },
        /* Date */
        UnitPrice: {
          title: 'Unit Price',
          filter:true,
          type: 'number'
        },
        /* Lookup */
        Rejected: {
          title: 'Rejected',
          filter:true,
          type: 'text'
        },
        /* Filtered Lookup - TODO: work out how to filter */
        ExcludedFromGPS: {
          title: 'Excluded From GPS',
          type:'string',
          filter:true
        },
        /* Lookup */
        DomesticFreightPerUnit: {
          title: 'Domestic Freight Per Unit',
          type: 'string',
          filter:true
        },
        ExportFreightPerUnitted: {
          title: 'Export Freight Per Unit',
          filter:true,
          type: 'text'
        },
       
        Commission: {
          title: 'Commission',
          type:'string',
          filter:true
        },
        /* Lookup */
        PromoCharge: {
          title: 'PromoCharge',
          type: 'string',
          filter:true
        }
      },
      mode: 'external',
      pager: {
        perPage: 20
      }
    });
  }
}