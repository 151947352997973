import { NgModule } from '@angular/core';
import { ThemeModule } from '../../@theme/theme.module';
import { NbWindowModule, NbInputModule, NbStepperModule, NbSelectModule, NbCheckboxModule } from '@nebular/theme';
import { PackagingComponent } from './packaging.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { PackagingService } from '../packaging/packaging.service';
import { DpDatePickerModule } from 'ng2-date-picker';
import { PackagingFormComponent } from './packaging-form/packaging-form.component';
import { PackagingApi } from './packaging.api';
import { PackagingTableComponent } from './packaging-table/packaging-table.component';
import { PackingFormComponent} from '../job/packing-form/packing-form.component';
import { PackingModule} from '../job/packing.module'
import {AppModule} from '../../app.module'

@NgModule({
  imports: [
    ThemeModule,
    NbWindowModule,
    NbInputModule,
    NbStepperModule,
    NbSelectModule,
    NbCheckboxModule,
    Ng2SmartTableModule,
    DpDatePickerModule,
    PackingModule
    
  ],
  declarations: [
    PackagingComponent,
    //PackagingFormComponent,
    PackagingTableComponent,
  ],
  providers: [
    PackagingService,
    PackagingApi
  ],
  exports: [
    //PackagingFormComponent,
    PackagingTableComponent
  ]
})

export class PackagingModule { }