import { Component, ViewChild, Output, OnInit,EventEmitter, OnDestroy } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { Ng2SmartTableComponent } from 'ng2-smart-table/ng2-smart-table.component';
import { ReceivalService } from '../receival.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { NbDialogService } from '@nebular/theme';
import { Subscription } from 'rxjs/internal/Subscription';
import { ReceivalViewModel, ReceivalRecord, ReceivalCreateRequest } from '../../../@core/models/receival-models';
import { Query } from '../../../@core/models/query-models';

@Component({
  selector: 'qualipac-receival-table',
  templateUrl: './receival-table.component.html',
  styles: ['receival-table.component.scss'],
})

export class ReceivalTableComponent{

  /// All these properties setup the Smart Table Component:
  // Let's us catch events if we wire up action buttons such as create etc
  @Output() rowSelected = new EventEmitter();
  // Allows the component source to talk to the dom element ('#table')
  @ViewChild('table') table: Ng2SmartTableComponent;
  // Model for the component
  source: LocalDataSource = new LocalDataSource();
  // UI setup for the component
  settings: {};

  query: Query;

  // listener for the data in the service to update the UI when
  // records are loaded or pushed via notification to the client
  receivalSubscriber: Subscription;
  //receivalRecords: ReceivalViewModel[];

  constructor(
    private receivals: ReceivalService,
    private translate: TranslateService,
    private router: Router,
    private datepipe: DatePipe,
    private dialogService: NbDialogService
  )
  {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => { 
      console.log('translating')
      this.renderSettings();
    });
    
    this.receivalSubscriber = this.receivals.receivals.subscribe(
      receivals => {
        this.source = new LocalDataSource(receivals);
      }
    )
    //this.receivals.search();
    this.renderSettings();
    
  }

  onSelect(event): void {
    // we'll bounce to a detail page
    this.rowSelected.emit(event.data.PalletId);
  }

  pageForward(){
    this.receivals.pageForward();
  }

  pageBack(){
    this.receivals.pageBack();
  }

  newReceival(){
    //this.router.navigateByUrl("/qualipac/new-receival");
    this.router.navigateByUrl("/qualipac/new-packing");
  }

  // translateRecords(receivals: ReceivalViewModel[]) {
  //   receivals.forEach(receival => {
  //     receival.date_label = this.datepipe.transform(receival.Date, 'shortDate');
  //     this.translate.get(receival.Variety).subscribe(translation => {
  //       receival.variety_label = translation;
  //     });
  //     this.translate.get(receival.Supplier).subscribe(translation => {
  //       receival.supplier_label = translation;
  //     })
  //   });
  //   return receivals;
  // }

  // set up the smart table in the ui with links to the model (columns)
  // and action buttons (add/edit etc)
  // pallet for example - is the name of the model propery for the table row.
  renderSettings(){
    this.settings = Object.assign({}, {
      actions: false,
      columns: {
        /* Generated */
        PalletNumber: {
          title: 'Pallet',
          type: 'number',
          filter: true
        },
        /* Date */
        date_label: {
          title: 'Date',
          filter:true,
          type: 'text'
        },
        /* Lookup */
        supplier_label: {
          title: 'Supplier',
          filter:true,
          type: 'text'
        },
        /* Filtered Lookup - TODO: work out how to filter */
        Block: {
          title: 'Block',
          type:'string',
          filter:true
        },
        /* Lookup */
        variety_label: {
          title: 'Variety',
          type: 'string',
          filter:true
        }
      },
      mode: 'external',
      pager: {
        perPage: 20
      }
    });
  }
}