import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { QualipacComponent } from './qualipac.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PackagingComponent } from './packaging/packaging.component';
import { ReceivalComponent } from './receival/receival.component';
import { SalesComponent } from './sales/sales.component';
import { SettlementComponent } from './settlement/settlement.component';
import { NotFoundComponent } from '../@theme/miscellaneous/not-found/not-found.component';
import { MiscellaneousModule } from '../@theme/miscellaneous/miscellaneous.module';
import { DispatchComponent } from './dispatch/dispatch.component';
import { PackhouseComponent } from './packhouse/packhouse.component';
import { AccountingComponent } from './accounting/accounting.component';

const routes: Routes = [{
  path: '',
  component: QualipacComponent,
  children: [{
    path: 'dashboard',
    component: DashboardComponent,
  }, {
    path: 'packhouse',
    component: PackhouseComponent,
    children: [
      {
        path: 'receival',
        component: ReceivalComponent,
      },
      {
        path: 'packaging',
        component: PackagingComponent,
      },
      {
        path: 'dispatch',
        component: DispatchComponent,
      },
      {
        path: '',
        redirectTo: 'receival',
        pathMatch: 'full',
      }
    ]
  }, {
    path: 'accounts',
    component: AccountingComponent,
    children: [
      {
        path: 'sales',
        component: SalesComponent,
      }, {    
        path: 'settlement',
        component: SettlementComponent,
      },
      {
        path: '',
        redirectTo: 'sales',
        pathMatch: 'full',
      }
    ]
  }, {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  }, {
    path: '**',
    component: NotFoundComponent,
  }],
}];

@NgModule({
  imports: [
    MiscellaneousModule,
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule],
})

export class QualipacRoutingModule {
}