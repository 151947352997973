import { NbMenuItem } from '@nebular/theme';
import { MenuItem } from '../@theme/custom-nebular/menu-item';

export const MENU_ITEMS: MenuItem[] = [
  {
    key: 'UX.MENU.MAIN.DASHBOARD',
    title: 'Dashboard',
    icon: 'nb-home',
    link: '/qualipac/dashboard',
    home: true,
  },
  {
    key: 'UX.MENU.MAIN.PACKHOUSE',
    title: 'PACKHOUSE',
    group: true,
  },
  // {
  //   key: 'UX.MENU.MAIN.RECEIVAL',
  //   title: 'Receival',
  //   icon: 'ion-clipboard',
  //   link: '/qualipac/packhouse/receival',
  // },
  {
    key: 'UX.MENU.MAIN.PACKING',
    title: 'Packing',
    icon: 'ion-clipboard',
    link: '/qualipac/packhouse/packaging',
  },
  {
    key: 'UX.MENU.MAIN.DISPATCH',
    title: 'Dispatch',
    icon: 'ion-clipboard',
    link: '/qualipac/packhouse/dispatch',
  },
  {
    key: 'UX.MENU.MAIN.ACCOUNTS',
    title: 'ACCOUNTS',
    group: true,
  },
  {
    key: 'UX.MENU.MAIN.SALES',
    title: 'Sales',
    icon: 'ion-clipboard',
    link: '/qualipac/accounts/sales',
  },
  {
    key: 'UX.MENU.MAIN.SETTLEMENT',
    title: 'Settlement',
    icon: 'ion-clipboard',
    link: '/qualipac/accounts/settlement',
  },
  {
    key: 'UX.MENU.MAIN.GROWER',
    title: 'GROWER',
    group: true,
  },
  {
    key: 'UX.MENU.MAIN.PAYMENT',
    title: 'Payment',
    icon: 'ion-clipboard',
    link: '/qualipac/grower/payment',
  },
  {
    key: 'UX.MENU.MAIN.REPORT',
    title: 'Report',
    icon: 'ion-clipboard',
    link: '/qualipac/grower/report',
  },
];