import { Component, OnInit, ViewChild, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Ng2SmartTableComponent } from 'ng2-smart-table/ng2-smart-table.component';
import { PalletCreateRequest, PalletViewModel } from '../../../@core/models/pallet-models';
import { PackagingService } from '../packaging.service';
import { LookupService } from '../../lookup/lookup.service';
import { Router } from '@angular/router';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { LocalDataSource } from 'ng2-smart-table';
import { Observable } from 'rxjs';
import 'rxjs/add/observable/of';
import { ReceivalService } from '../../receival/receival.service';

@Component({
  selector: 'qualipac-packaging-form',
  templateUrl: 'packaging-form.html',
  styleUrls: ['packaging-form.component.scss']
})

export class PackagingFormComponent implements OnInit, OnDestroy {

  _palletId: string;
  @Input()
  set palletId(palletId: string) {
    this._palletId = (palletId && palletId.trim()) || '<no name set>';
    if(palletId) this.load();
  }

  packagingForm: FormGroup;
  packagingRecord: PalletCreateRequest;
  packagingSubscriber: Subscription;
  packagingWord: string = '';
  packagingData: PalletViewModel;

  constructor(
    private fb: FormBuilder,
    public packaging: PackagingService,
    public receivalService: ReceivalService,
    public lookup: LookupService,
    private router: Router,
    private translate: TranslateService,
    ) 
  {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => { 
      //this.renderSettings();
    });

    this.receivalService.selectedPackage.subscribe(data => {
      //console.log(packageData)
      this._palletId = data.PalletId
    })
  }

  load() {
    console.log("getting pallet id: ", this._palletId)
    this.packaging.getById(this._palletId);
  }

  ngOnInit(){
    this.packagingForm = this.fb.group({
      purchasedCtrl: '',
      packedCtrl:'',
      cutCtrl:'',
      brandCtrl:'',
      sizeCtrl:'',
      packagingCtrl: '',
      unitsCtrl: ''
    });
  }

  ngOnDestroy(){
    
  }

  onSubmit() {  
    this.packagingForm.markAsDirty()
    const newPackage = {
      palletId: this._palletId,
      purchased: this.packagingForm.value.purchasedCtrl,
      packed: this.packagingForm.value.packagingCtrl,
      cut: this.packagingForm.value.cutCtrl,
      brand: this.packagingForm.value.brandCtrl,
      size: this.packagingForm.value.sizeCtrl,
      packaging: this.packagingForm.value.packagingCtrl,
      units: this.packagingForm.value.unitsCtrl || 0
    }
    this.packaging.create(new PalletCreateRequest(newPackage))
    //this.router.navigateByUrl("/qualipac/packaging");
  }
}