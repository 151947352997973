import { Component, ViewChild, TemplateRef } from '@angular/core';
import { PackingService } from './packing.service';
import { NbWindowService } from '@nebular/theme';
import { PackingFormComponent } from './packing-form/packing-form.component';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ngx-packing',
  templateUrl: 'packing.component.html',
  styleUrls: ['packing.component.scss'],
})
export class PackingComponent {

@ViewChild('palletTemplate') palletTemplate: TemplateRef<any>;

  firstCard = {
    pallets: [],
    placeholders: [],
    loading: false,
    pageToLoadNext: 1,
  };
  secondCard = {
    pallets: [],
    placeholders: [],
    loading: false,
    pageToLoadNext: 1,
  };
  pageSize = 20;

  constructor(
    private packingService: PackingService,
    private router: Router, 
    private route: ActivatedRoute
    ) {

    }

  loadNext(cardData) {
    if (cardData.loading) { return; }

    cardData.loading = true;
    cardData.placeholders = new Array(this.pageSize);
    this.packingService.load(cardData.pageToLoadNext, this.pageSize)
      .subscribe(qualipacRecords => {
        cardData.placeholders = [];
        cardData.pallets.push(...qualipacRecords);
        cardData.loading = false;
        cardData.pageToLoadNext++;
      });
  }

  openPalletForm() {
    this.router.navigate([`../new-pallet`], { relativeTo: this.route });
  }
}
