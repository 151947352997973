import { NgModule } from '@angular/core';

import { ThemeModule } from '../../@theme/theme.module';
import { NbWindowModule, NbInputModule, NbStepperModule, NbSelectModule, NbCheckboxModule } from '@nebular/theme';
import { SalesComponent } from './sales.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { SalesService } from '../sales/sales.service';
import { DateFormatPipe } from '../../@theme/pipes/dateFormat.pipe';
import { DpDatePickerModule } from 'ng2-date-picker';
import { SalesFormComponent } from './sales-form/sales-form.component';
import { SalesApi } from './sales.api';

@NgModule({
  imports: [
    ThemeModule,
    NbWindowModule,
    NbInputModule,
    NbStepperModule,
    NbSelectModule,
    NbCheckboxModule,
    Ng2SmartTableModule,
    DpDatePickerModule
  ],
  declarations: [
    SalesComponent,
  ],
  providers: [
    SalesService,
    SalesApi
  ],
  exports: [
    
  ]
})

export class SalesModule { }