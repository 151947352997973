import { SettlementApi } from './settlement.api';
import { Observable, BehaviorSubject } from 'rxjs';
import * as uuid from 'uuid';
import { Query } from '../../@core/models/query-models';
import { Injectable } from '@angular/core';
import { AuthService } from '../../@auth/auth.service';
import { SettlementViewModel, SettlementCreateRequest } from '../../@core/models/settlement-models';

@Injectable()
export class SettlementService {

    settlements: Observable<Array<SettlementViewModel>>;
    private _settlements: BehaviorSubject<Array<SettlementViewModel>>;
    
    private dataStore: {
        settlements: Array<SettlementViewModel>
    }

    loading: boolean = false;
    query: Query;

    constructor(
        private settlementApi: SettlementApi,
        private auth: AuthService
        ){

        // This is a pattern I've found really useful, which 
        // is probably like a roll-your-own Redux but just 
        // used for a single aspect (recivals feature)
        this.dataStore = {
            settlements: new Array<SettlementViewModel>(),
        }    

        this._settlements = <BehaviorSubject<SettlementViewModel[]>>new BehaviorSubject([]);
        this.settlements = this._settlements.asObservable();

        this.query = new Query({page:1, size:200});
        this.search();
    }

    // Check we haven't already got this record then
    // add it to the service's dataset. Convert it 
    // from an api object to a view mode;
    // NB: When we have view models on the api, 
    // the response will come back in the right shape and this will not be necessary
    addSettlement(settlement: any) {
        if(this.dataStore.settlements.findIndex(r => r.PalletId === settlement.PalletId) > -1) {
            this.dataStore.settlements.push(
                new SettlementViewModel(settlement)
            );
        }
    }

    // This isnt currently wired up and is dependent
    // on the api passing back totals.
    pageForward(){
        this.query.page += 1;
        this.search();
    }

    // This isnt currently wired up and is dependent
    // on the api passing back totals.
    pageBack(){
        this.query.page -= 1;
        this.search();
    }

    // calling the api for data, parsing, then updating the subscribers
    // this will be triggered on load, search, and on paging
    search() {
        let fault: boolean = false;
        this.settlementApi
            .search(this.query)
            .subscribe(
            // This is the on-success callback
            settlements => {
                settlements.forEach(settlement => this.addSettlement(settlement));            
            },
            // If an error happens..
            error => {
                fault = true;
                console.log(error);
            },
            // This always calls on termination 
            // Like the Finally of a try block
            () => {
                // now flush the internal state to the subscribers
                this._settlements.next(Object.assign({}, this.dataStore).settlements);
                console.log(`Settlements loading complete ${ fault ? 'with' : 'without'} fault.`);
            }
        );
    }

    create(settlement: SettlementCreateRequest) {
        let fault: Boolean = false;
        settlement.User = this.auth.getUser().id;
        this.settlementApi.create(settlement).subscribe(
            success => {
                console.log(success);
                this.addSettlement(settlement);
            },
            error => {
                console.error(error);
            },
            () => {
                this.loading = false;
                this._settlements.next(Object.assign({}, this.dataStore).settlements);
                console.log(`Settlement creation complete ${ fault ? 'with' : 'without'} fault.`);
            }
        );
    }

    update() {

    }

    delete() {

    }
}