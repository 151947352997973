import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DispatchService } from '../dispatch.service';
import { LookupService } from '../../lookup/lookup.service';
import { DispatchCreateRequest } from '../../../@core/models/dispatch-models';
import { Router } from '@angular/router';
import { PackagingService } from '../../packaging/packaging.service';

@Component({
  selector: 'qualipac-dispatch-form',
  templateUrl: 'dispatch-form.html',
  styleUrls: ['dispatch-form.component.scss']
})

export class DispatchFormComponent implements OnInit {

  _palletId: string;
  // @Input()
  // set palletId(palletId: string) {
  //   console.log("HELLO from dispatch")
  //   this._palletId = (palletId && palletId.trim()) || '<no name set>';
  //   if(palletId) this.load();
  // }

  dispatchForm: FormGroup;
  dispatchRecord: DispatchCreateRequest;

  constructor(
    private fb: FormBuilder,
    public dispatch: DispatchService,
    public packageService: PackagingService,
    public lookup: LookupService,
    private router: Router
    ) 
  {
    this.packageService.selectedPackage.subscribe(data=>{
      this._palletId = data.palletid
    })
  }

  ngOnInit(){
    this.dispatchForm = this.fb.group({
      dispatchDateCtrl: '',
      deliveryDateCtrl: '',
      dispatchNoteCtrl: '',
      freighterCtrl: '',
      freightPaymentCtrl: '',
      agentCtrl: '',
      exportCtrl: false,
      destinationCtrl: '',
    });
  }
  
  onSubmit() {
    this.dispatchForm.markAsDirty();
    const newDispatch = {
      palletid: this._palletId,
      dispatchdate: this.dispatchForm.value.dispatchDateCtrl,
      deliverydate: this.dispatchForm.value.deliveryDateCtrl,
      dispatchnote: this.dispatchForm.value.dispatchNoteCtrl,
      freightcompany: this.dispatchForm.value.freighterCtrl,
      agent: this.dispatchForm.value.agentCtrl,
      export: this.dispatchForm.value.exportCtrl
    }
    console.log('EXPORT = ', newDispatch.export)
    this.dispatch.create(new DispatchCreateRequest(newDispatch))
  }
}