import { Component } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'fsc-auth',
  styleUrls: ['auth.component.scss'],
  template: `
    <ngx-sample-layout>
      <router-outlet></router-outlet>
    </ngx-sample-layout>
  `,
})

export class AuthComponent {
  constructor(private translate:TranslateService) {

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => { //Live reload
      console.log("Changing the pages menu..");
      this.translateMenu();
    });
  }

  ngOnInit() {
    this.translateMenu();
    }

    private translateMenu(): void {
    
    }
}