import { Component, Input, OnInit } from '@angular/core';
import { NbMenuService, NbSidebarService, NbMenuItem } from '@nebular/theme';
import { AnalyticsService } from '../../../@core/utils';
import { LayoutService } from '../../../@core/utils';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { AuthService, AuthState } from '../../../@auth/auth.service';
import { CognitoUtil } from '../../../@auth/cognito.service';
import { AmplifyService } from 'aws-amplify-angular';
import { Router } from '@angular/router';
import { UserLoginService } from '../../../@auth/user-login.service';
import { AWS } from '@aws-amplify/core';
import { UserProfile } from '../../../@core/models/user-models';
import { Hub } from '@aws-amplify/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {

  @Input() position = 'normal';

  user: AuthState;
  customer: string = '';
  userMenu = [{ title: 'test1' }, { title: 'test2' }];
  signedIn:boolean;

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private analyticsService: AnalyticsService,
    private layoutService: LayoutService,
    public translate: TranslateService,
    private auth: AuthService,
    private cognito: CognitoUtil,
    private amplifyService: AmplifyService,
    private router: Router,
    private userService: UserLoginService,
    private loginService: UserLoginService
  ) {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => { //Live reload
      this.translateMenu();
    });

    // this.userService.
    
    //AWS.Config.  //.config.credentials

    this.auth.auth$.subscribe(user => {
      if(user.isLoggedIn) {
        this.user = user;
      }
      // console.log('user is logged in');
      // console.log(user);
      // this.user = user;
    });


    this.amplifyService.authStateChange$
      .subscribe((authState:any) => {
        console.log(`auth state updated to ${authState.state}`);
        this.signedIn = authState.state === 'signedIn';
        if (authState.idToken) {
          this.user = {
            isLoggedIn: true,
            username: authState.idToken.payload.username,
            name: authState.idToken.payload.name,
            id: authState.idToken.payload.sub,
            email: authState.idToken.payload.email,
            idToken: authState.idToken.jwtToken
          };
          this.router.navigateByUrl('/');
        } else {
          this.router.navigateByUrl('/auth/login');
        }
      });
    }

  ngOnInit() {
    this.translateMenu();
    this.menuService.onItemClick().subscribe(( event ) => {
      this.onItemSelection(event.item);
    });
  }

  onItemSelection( item: NbMenuItem ) {
    if(this.translate.instant('UX.MENU.USER.LOGOUT') === item.title){
      console.log('Log out Clicked ');
      this.loginService.logout();
    }
    if(this.translate.instant('UX.MENU.USER.PROFILE') === item.title){
      console.log('Profile Clicked ');
    }
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();
    return false;
  }

  translateMenu(){
    this.translate
    .get('BUSINESS.NIHON.NAME')
    .subscribe((res: string) => {
      this.customer = res;
    });

    this.translate
    .get('UX.MENU.USER.PROFILE')
    .subscribe((res: string) => {
      this.userMenu[0].title = res;
    });
    
    this.translate
    .get('UX.MENU.USER.LOGOUT')
    .subscribe((res: string) => {
      this.userMenu[1].title = res;
    });
  }

  changeLanguage(lang:string) {
    console.log(`change language to: ${lang}`);
    this.translate.use(lang);
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  startSearch() {
    this.analyticsService.trackEvent('startSearch');
  }
}