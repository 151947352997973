import { Component, ViewChild, Output, OnInit, OnDestroy } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { Ng2SmartTableComponent } from 'ng2-smart-table/ng2-smart-table.component';
import { EventEmitter } from 'events';
import { ReceivalService } from './receival.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { NbDialogService } from '@nebular/theme';
import { Subscription } from 'rxjs/internal/Subscription';
import { ReceivalViewModel, ReceivalRecord, ReceivalCreateRequest } from '../../@core/models/receival-models';
import { Query } from '../../@core/models/query-models';
import { PackhouseService } from '../packhouse/packhouse.service';

@Component({
  selector: 'ngx-receival',
  templateUrl: './receival.component.html',
  styles: [`
    nb-card {
      transform: translate3d(0, 0, 0);
      border: 0px;
    }
  `],
})

export class ReceivalComponent implements 
  OnInit, 
  OnDestroy 
  {

  /// All these properties setup the Smart Table Component:
  // Let's us catch events if we wire up action buttons such as create etc
  @Output() create: EventEmitter = new EventEmitter();
  // Allows the component source to talk to the dom element ('#table')
  @ViewChild('table') table: Ng2SmartTableComponent;
  // Model for the component
  source: LocalDataSource = new LocalDataSource();
  // UI setup for the component
  settings: {};

  query: Query;
  selectedIndex: number = 1
  // listener for the data in the service to update the UI when
  // records are loaded or pushed via notification to the client
  receivalSubscriber: Subscription;
  //receivalRecords: ReceivalViewModel[];

  constructor(
    private receivals: ReceivalService,
    private translate: TranslateService,
    private router: Router,
    private datepipe: DatePipe,
    private dialogService: NbDialogService,
    private route: ActivatedRoute,
    private packhouse: PackhouseService
  )
  {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => { 
      console.log('translating')
      this.renderSettings();
    });
    
    this.receivalSubscriber = this.receivals.receivals.subscribe(
      receivals => {
        this.source = new LocalDataSource(receivals);
      }
    )

    this.route.url.subscribe(segment => {
      console.log('route changed!');
      const currentStep = segment[segment.length - 1].path;
      if(currentStep) this.packhouse.setCurrentStep(currentStep);
    });
  }

  loadTableData(receivals?: ReceivalViewModel[]) {
    if(receivals) {
      this.source = new LocalDataSource(this.translateRecords(receivals));
    }
  }

  ngOnInit(){

  }

  ngOnDestroy(){
    this.receivalSubscriber.unsubscribe();
  }



  pageForward(){
    this.receivals.pageForward();
  }

  pageBack(){
    this.receivals.pageBack();
  }
  rowSelected(palletId: string) {
    this.receivals.getById(palletId);
  }

  newReceival(){
    //this.router.navigateByUrl("/qualipac/new-receival");
    this.router.navigateByUrl("/qualipac/new-packing");
  }

  translateRecords(receivals: ReceivalViewModel[]) {
    receivals.forEach(receival => {
      receival.date_label = this.datepipe.transform(receival.Date, 'shortDate');
      this.translate.get(receival.Variety).subscribe(translation => {
        receival.variety_label = translation;
      });
      this.translate.get(receival.Supplier).subscribe(translation => {
        receival.supplier_label = translation;
      })
    });
    return receivals;
  }

  // set up the smart table in the ui with links to the model (columns)
  // and action buttons (add/edit etc)
  // pallet for example - is the name of the model propery for the table row.
  renderSettings(){
    this.settings = Object.assign({}, {
      actions: false,
      columns: {
        /* Generated */
        PalletNumber: {
          title: 'Pallet',
          type: 'number',
          filter: true
        },
        /* Date */
        date_label: {
          title: 'Date',
          filter:true,
          type: 'text'
        },
        /* Lookup */
        supplier_label: {
          title: 'Supplier',
          filter:true,
          type: 'text'
        },
        /* Filtered Lookup - TODO: work out how to filter */
        Block: {
          title: 'Block',
          type:'string',
          filter:true
        },
        /* Lookup */
        variety_label: {
          title: 'Variety',
          type: 'string',
          filter:true
        }
      },
      mode: 'external',
      pager: {
        perPage: 20
      }
    });
  }
}