import { Component, ViewChild, Output, OnInit, OnDestroy } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { PackingService, PackhouseRecord } from '../job/packing.service';
import { Ng2SmartTableComponent } from 'ng2-smart-table/ng2-smart-table.component';
import { EventEmitter } from 'events';
import { SettlementService } from './settlement.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { NbDialogService } from '@nebular/theme';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ngx-settlement',
  templateUrl: './settlement.component.html',
  styles: [`
    nb-card {
      transform: translate3d(0, 0, 0);
    }
  `],
})
export class SettlementComponent implements
  OnInit, 
  OnDestroy 
 {

  @ViewChild('table') table: Ng2SmartTableComponent;
  @Output() create: EventEmitter = new EventEmitter();

  source: LocalDataSource = new LocalDataSource();
  settings:{};

  settlementSubscriber: Subscription;

  constructor(
    private settlementService: SettlementService,
    private translate: TranslateService,
    private router: Router,
    private datepipe: DatePipe,
    private dialogService: NbDialogService
  ) 
  {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => { 
      this.renderSettings();
    });

    this.settlementSubscriber = this.settlementService.settlements.subscribe(
      settlements => {
        this.source = new LocalDataSource(settlements);
      }
    )

    this.renderSettings();
    this.settlementService.search();
  }

  ngOnInit(){}

  ngOnDestroy(){
    this.settlementSubscriber.unsubscribe();
  }

  onSelect(event): void {
    console.log('record selected');
    console.log(event);
  }

  newSettlement(){
    this.router.navigateByUrl("/qualipac/new-settlement");
  }

  pageForward(){
    this.settlementService.pageForward();
  }

  pageBack(){
    this.settlementService.pageBack();
  }

  renderSettings(){
    this.settings = Object.assign({}, {
      actions: false,
      columns: {
        /* Generated */
        PalletNumber: {
          title: 'Pallet',
          type: 'number',
          filter: true
        },
        /* Date */
        Date: {
          title: 'Date',
          filter:true,
          type: 'text'
        },
        /* Lookup */
        Supplier: {
          title: 'Supplier',
          filter:true,
          type: 'text'
        },
        /* Filtered Lookup - TODO: work out how to filter */
        Block: {
          title: 'Block',
          type:'string',
          filter:true
        },
        /* Lookup */
        Variety: {
          title: 'Variety',
          type: 'string',
          filter:true
        }
      },
      mode: 'external',
      pager: {
        perPage: 20
      }
    });
  }
}

/*
this.source.onUpdated().subscribe(update => {
      // TODO: Calculate the value of grower payments if fields support it.
      console.log('Something Updated');
      console.log(update);

      let unitPriceNumber = update.unitPrice as number;
      let domesticFreightNumber = update.domesticFreightPerUnit as number;
      let exportFreightNumber = update.exportFreightPerUnit as number;
      let totalFreightPerUnitNumber: number = domesticFreightNumber + exportFreightNumber;
      let commissionNumber = update.commission as number;
      let rebateNumber = update.rebate as number;
      let promoChargeNumber = update.promoCharge as number;

      update.totalFreightPerUnit = totalFreightPerUnitNumber;

      let unitPriceToGrowerNumber: number = unitPriceNumber 
        - totalFreightPerUnitNumber
        - (unitPriceNumber * commissionNumber)
        - (unitPriceNumber * rebateNumber)
        - promoChargeNumber;

      update.unitPriceToGrower = unitPriceToGrowerNumber;
    });
*/