import { NgModule } from '@angular/core';
import { ThemeModule } from '../../@theme/theme.module';
import { NbWindowModule, NbInputModule, NbStepperModule, NbSelectModule, NbCheckboxModule } from '@nebular/theme';
import { ReceivalComponent } from './receival.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { PackingService } from '../job/packing.service';
import { DateFormatPipe } from '../../@theme/pipes/dateFormat.pipe';
import { DpDatePickerModule } from 'ng2-date-picker';
import { ReceivalService } from './receival.service';
import { ReceivalFormComponent } from './receival-form/receival-form.component';
import { ReceivalApi } from './receival.api';
import {ReceivalTableComponent} from './receival-table/receival-table.component';
import { PackingModule} from '../job/packing.module';
@NgModule({
  imports: [
    ThemeModule,
    NbWindowModule,
    NbInputModule,
    NbStepperModule,
    NbSelectModule,
    NbCheckboxModule,
    Ng2SmartTableModule,
    DpDatePickerModule,
    PackingModule
  ],
  declarations: [
    ReceivalComponent,
    ReceivalTableComponent
  ],
  providers: [
    ReceivalService,
    ReceivalApi
  ],
  exports: [
    ReceivalTableComponent
  ]
})

export class ReceivalModule { }