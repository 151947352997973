import { Component, OnInit, ViewChild, OnDestroy, EventEmitter, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Ng2SmartTableComponent } from 'ng2-smart-table/ng2-smart-table.component';
import { PalletCreateRequest } from '../../../@core/models/pallet-models';
import { PackagingService } from '../packaging.service';
import { LookupService } from '../../lookup/lookup.service';
import { Router } from '@angular/router';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { LocalDataSource } from 'ng2-smart-table';

@Component({
  selector: 'qualipac-packaging-table',
  templateUrl: 'packaging-table.component.html',
  styleUrls: ['packaging-table.component.scss']
})

export class PackagingTableComponent {

    @Output() rowSelected = new EventEmitter();
    
    /* Bring the magic of a smart table */
    @ViewChild('table') table: Ng2SmartTableComponent;
    source: LocalDataSource = new LocalDataSource();
    
    // UI setup for the component
    settings: {};
    packagingSubscriber: Subscription;

    constructor(
        public packaging: PackagingService,
        public lookup: LookupService,
        public translate: TranslateService
    ){
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => { 
            this.renderSettings();
          });
      
        this.packagingSubscriber = this.packaging.packagings.subscribe(
            packagings => {
                this.source = new LocalDataSource(packagings);
            }
        );

        this.renderSettings();
    }

    onSelect(event): void {
        console.log("Selecting packaging cell");
        //emit to parent
        this.rowSelected.emit(event.data.PalletId);
    }

    renderSettings(){
        this.settings = Object.assign({}, {
          actions: false,
          columns: {
            /* Generated */
            PalletNumber: {
              title: 'Pallet',
              type: 'number',
              filter: true
            },
            /* Date */
            Purchased: {
              title: 'Date',
              filter:true,
              type: 'text'
            },
            /* Lookup */
            Packed: {
              title: 'Packed',
              filter:true,
              type: 'text'
            },
            /* Filtered Lookup - TODO: work out how to filter */
            Cut: {
              title: 'Block',
              type:'string',
              filter:true
            },
            /* Lookup */
            Brand: {
              title: 'Brand',
              type: 'string',
              filter:true
            },
            Size: {
              title: 'Size',
              type: 'string',
              filter:true
            },
            Packaging: {
              title: 'Packaging',
              type: 'string',
              filter:true
            },
            Units: {
              title: 'Variety',
              type: 'string',
              filter:true
            }
          },
          mode: 'external',
          pager: {
            perPage: 20
          }
        });
    }
}