import {Component} from "@angular/core";
import {Router} from "@angular/router";
import {UserRegistrationService} from "../user-registration.service";
import {UserLoginService} from "../user-login.service";
import {CognitoCallback} from "../cognito.service";
import { TranslateService } from '@ngx-translate/core';

export class NewPasswordUser {
    username: string;
    existingPassword: string;
    password: string;
}

/**
 * This component is responsible for displaying and controlling
 * the registration of the user.
 */
@Component({
    selector: 'awscognito-angular2-app',
    templateUrl: './newpassword.html'
})

export class NewPasswordComponent implements CognitoCallback {
    registrationUser: NewPasswordUser;
    router: Router;
    errorMessage: string;

    constructor(
        public userRegistration: UserRegistrationService, 
        public userService: UserLoginService, 
        router: Router,
        translate: TranslateService
        ) {
        this.router = router;
        this.onInit();
    }

    onInit() {
        this.registrationUser = new NewPasswordUser();
        this.errorMessage = null;
    }

    ngOnInit() {
        this.errorMessage = null;
        console.log("Checking if the user is already authenticated. If so, then redirect to the secure site");
        this.userService.isAuthenticated(this);
    }

    onRegister() {
        console.log(this.registrationUser);
        this.errorMessage = null;
        this.userRegistration.newPassword(this.registrationUser, this);
    }

    cognitoCallback(message: string, result: any) {
        if (message != null) { //error
            this.errorMessage = message;
            console.log("result: " + this.errorMessage);
        } else { //success
            //move to the next step
            console.log("redirecting");
            this.router.navigate(['/nihon']);
        }
    }

    isLoggedIn(message: string, isLoggedIn: boolean) {
        if (isLoggedIn)
            this.router.navigate(['/nihon']);
    }
}