import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LookupService } from '../../lookup/lookup.service';
import { ReceivalService } from '../../receival/receival.service';
import { ReceivalCreateRequest } from '../../../@core/models/receival-models';
import { PackagingService } from '../../packaging/packaging.service';
import { PalletCreateRequest } from '../../../@core/models/pallet-models'
import { DispatchService } from '../../dispatch/dispatch.service';
import { DispatchCreateRequest } from '../../../@core/models/dispatch-models'
import { SalesService } from '../../sales/sales.service';
import { SalesCreateRequest } from '../../../@core/models/sales-models'
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { integer } from 'aws-sdk/clients/cloudfront';

@Component({
  selector: 'qualipac-accounting-form',
  templateUrl: 'accounting-form.html',
  styleUrls: ['accounting-form.component.scss']
})

export class AccountingFormComponent implements OnInit {

  _palletId: string;
  
  @Input()
  set palletId(palletId: string) {
    console.log("inputting")
    this._palletId = (palletId && palletId.trim()) || '<no name set>';
    if(palletId) this.load();
  }

  @Input()
  _selectIndex: number;

  Subscriber: Subscription;
  receivalForm: FormGroup;
  packagingForm: FormGroup;
  dispatchForm: FormGroup;
  saleForm: FormGroup;
  //selectedSupplier: SupplierRecord;
  supplierMetricsVisible: boolean = false;
  supplierName: string = '';
  suppilerMetrics:any;

  constructor(
    private fb: FormBuilder,
    //public packing: PackingService,
    private receival: ReceivalService,
    private packaging: PackagingService,
    private dispatch: DispatchService,
    private sale: SalesService,
    public lookup: LookupService,
    private translate: TranslateService
  ) 
  {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => { 
      this.render();
    });

    this.render();
  }

  load() {
    console.log("getting pallet id: ", this._palletId)
    /** Not sure what we do here */
    
  }

  render(){}

  ngOnInit(){
    // this.receivalForm = this.fb.group({
    //   supplierCtrl: '',
    //   palletCtrl: '',
    //   dateCtrl: new Date(),
    //   blockCtrl: '',
    //   varietyCtrl: '',
    //   soldCtrl: '',
    //   packedCtrl: '',
    //   cutCtrl: '',
    //   brandCtrl: '',
    //   sizeCtrl: '',
    //   packagingCtrl: '',
    //   unitsCtrl: ''
    // });

    // this.packagingForm = this.fb.group({
    //   purchasedCtrl: '',
    //   packedCtrl:'',
    //   cutCtrl:'',
    //   brandCtrl:'',
    //   sizeCtrl:'',
    //   packagingCtrl:'',
    //   unitsCtrl: ''
    // });

    // this.dispatchForm = this.fb.group({
    //   dispatchDateCtrl: '',
    //   deliveryDateCtrl: '',
    //   dispatchNoteCtrl: '',
    //   freighterCtrl: '',
    //   freightPaymentCtrl: '',
    //   agentCtrl: '',
    //   exportCtrl: '',
    //   destinationCtrl: '',
    // });

    // this.saleForm = this.fb.group({
    //   unitPriceCtrl: '',
    //   rejectedCtrl: '',
    //   excludedFromGPSCtrl: '',
    //   domesticFreightPerUnitCtrl: '',
    //   exportFreightPerUnitCtrl: '',
    //   commissionCtrl: '',
    //   promoChargeCtrl: '',
    // });

    // this.onChange();
  }

  ngOnDestroy(){
    
  }

  // onChange(): void {
  //   this.receivalForm.get('supplierCtrl').valueChanges.subscribe(val => {
  //     this.supplierMetricsVisible = true;
  //     this.supplierName = this.receivalForm.get('supplierCtrl').value;
  //     if(!this.receivalForm.controls['palletCtrl'].value)
  //       this.receivalForm.controls['palletCtrl'].setValue(this.packing.getNextPalletNumber().toString());
  //     if(!this.receivalForm.controls['dateCtrl'].value)
  //       this.receivalForm.controls['dateCtrl'].setValue(this.packing.getPackhouseDate());
  //   });
  // }

  // onReceivalSubmit() {
  //   this.receivalForm.markAsDirty();
  //   const testObj = {
  //     date: this.receivalForm.value.dateCtrl,
  //     block: this.receivalForm.value.blockCtrl,
  //     variety: this.receivalForm.value.varietyCtrl,
  //     supplier: this.receivalForm.value.supplierCtrl
  //   }
  //   const newJob = new ReceivalCreateRequest(testObj)
  //   this._palletId = newJob.PalletId
  //   this.receival.create(newJob);
  // }

  // onPackagingSubmit(){
  //   this.packagingForm.markAsDirty()
  //   const newPackage = {
  //     palletId: this._palletId,
  //     purchased: this.packagingForm.value.purchasedCtrl,
  //     packed: this.packagingForm.value.packagingCtrl,
  //     cut: this.packagingForm.value.cutCtrl,
  //     brand: this.packagingForm.value.brandCtrl,
  //     size: this.packagingForm.value.sizeCtrl,
  //     packaging: this.packagingForm.value.packagingCtrl,
  //     units: this.packagingForm.value.unitsCtrl || 0
  //   }
  //   this.packaging.create(new PackagingCreateRequest(newPackage))
  // }

  // onDispatchSubmit() {
  //   this.dispatchForm.markAsDirty();
  //   const newDispatch = {
  //     palletId: this._palletId,
  //     dispatchDate: this.dispatchForm.value.dispatchDateCtrl,
  //     deliveryDate: this.dispatchForm.value.deliveryDateCtrl,
  //     dispatchNote: this.dispatchForm.value.dispatchNoteCtrl,
  //     freighter: this.dispatchForm.value.freighterCtrl,
  //     agent: this.dispatchForm.value.agentCtrl,
  //     export: this.dispatchForm.value.exportCtrl || 0
  //   }
  //   console.log('EXPORT = ', newDispatch.export)
  //   this.dispatch.create(new DispatchCreateRequest(newDispatch))
  // }

  // onSaleSubmit() {
  //   this.saleForm.markAsDirty();
  //   const newSale = {
  //     palletId: this._palletId,
  //     unitPrice: this.saleForm.value.unitPriceCtrl,
  //     rejected: this.saleForm.value.rejectedCtrl,
  //     excludedFromGPS: this.saleForm.value.excludedFromGPSCtrl,
  //     domesticFreightPerUnit: this.saleForm.value.domesticFreightPerUnitCtrl,
  //     exportFreightPerUnit: this.saleForm.value.exportFreightPerUnitCtrl,
  //     commission: this.saleForm.value.commissionCtrl,
  //     promoCharge: this.saleForm.value.promoChargeCtrl
  //   }
  //   this.sale.create(new SalesCreateRequest(newSale))
  // }
}