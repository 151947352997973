import { DispatchApi } from './dispatch.api';
import { Observable, BehaviorSubject } from 'rxjs';
import * as uuid from 'uuid';
import { Query } from '../../@core/models/query-models';
import { Injectable } from '@angular/core';
import { DispatchViewModel, DispatchRecord, DispatchCreateRequest } from '../../@core/models/dispatch-models';
import { AuthService } from '../../@auth/auth.service';

@Injectable()
export class DispatchService {

    dispatchs: Observable<Array<DispatchViewModel>>;
    private _dispatchs: BehaviorSubject<Array<DispatchViewModel>>;

    selectedDispatch: Observable<DispatchViewModel>;
    private _selectedDispatch: BehaviorSubject<DispatchViewModel>;

    private dataStore: {
        dispatchs: Array<DispatchViewModel>,
        selectedDispatch: DispatchViewModel
    }

    loading: boolean = false;
    query: Query;

    constructor(
        private dispatchApi: DispatchApi,
        private auth: AuthService
        ){

        // This is a pattern I've found really useful, which 
        // is probably like a roll-your-own Redux but just 
        // used for a single aspect (recivals feature)
        this.dataStore = {
            dispatchs: new Array<DispatchViewModel>(),
            selectedDispatch: new DispatchViewModel()
        }    

        this._dispatchs = <BehaviorSubject<DispatchViewModel[]>>new BehaviorSubject([]);
        this.dispatchs = this._dispatchs.asObservable();

        this._selectedDispatch = <BehaviorSubject<DispatchViewModel>>new BehaviorSubject({});
        this.selectedDispatch = this._selectedDispatch.asObservable();

        this.query = new Query({page:1, size:200});
        this.search();
    }

    // Check we haven't already got this record then
    // add it to the service's dataset. Convert it 
    // from an api object to a view mode;
    // NB: When we have view models on the api, 
    // the response will come back in the right shape and this will not be necessary
    addDispatch(dispatch: any) {
        if(this.dataStore.dispatchs.findIndex(r => r.palletid === dispatch.palletid) === -1) {
            this.dataStore.dispatchs.push(
                new DispatchViewModel(dispatch)
            );
        }
    }

    // This isnt currently wired up and is dependent
    // on the api passing back totals.
    pageForward(){
        this.query.page += 1;
        this.search();
    }

    // This isnt currently wired up and is dependent
    // on the api passing back totals.
    pageBack(){
        this.query.page -= 1;
        this.search();
    }

    // calling the api for data, parsing, then updating the subscribers
    // this will be triggered on load, search, and on paging
    search() {
        let fault: boolean = false;
        this.dispatchApi
            .search(this.query)
            .subscribe(
            // This is the on-success callback
            async (dispatchs) => {
                let dispatchGroup: Array<DispatchViewModel> = [];
                (await dispatchs as []).forEach(dispatch => {
                    dispatchGroup.push(new DispatchViewModel(dispatch));
                });
                dispatchGroup.forEach(item => this.dataStore.dispatchs.push(item));
                this._dispatchs.next(Object.assign({}, this.dataStore).dispatchs);

            },
            // If an error happens..
            error => {
                fault = true;
                console.log(error);
            },
            // This always calls on termination 
            // Like the Finally of a try block
            () => {
                // now flush the internal state to the subscribers
                this._dispatchs.next(Object.assign({}, this.dataStore).dispatchs);
                console.log(`Dispatchs loading complete ${ fault ? 'with' : 'without'} fault.`);
            }
        );
    }

    getById(id: string){
        this.dispatchApi.get(id).subscribe(async pallet => {
            const palletData = await pallet;
            this.dataStore.selectedDispatch = new DispatchViewModel(palletData[0])
            this._selectedDispatch.next(Object.assign({}, this.dataStore).selectedDispatch)
        })
    }

    create(dispatch: DispatchCreateRequest) {
        let fault: Boolean = false;
        this.dispatchApi.create(dispatch).subscribe(
            success => {
                console.log(success);
                this.addDispatch(dispatch);
            },
            error => {
                console.error(error);
            },
            () => {
                this.loading = false;
                this._dispatchs.next(Object.assign({}, this.dataStore).dispatchs);
                console.log(`Dispatch creation complete ${ fault ? 'with' : 'without'} fault.`);
            }
        );
    }

    update() {

    }

    delete() {

    }
}