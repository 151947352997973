import { SalesApi } from './sales.api';
import { Observable, BehaviorSubject } from 'rxjs';
import * as uuid from 'uuid';
import { Query } from '../../@core/models/query-models';
import { Injectable } from '@angular/core';
import { SalesViewModel, SalesRecord, SalesCreateRequest } from '../../@core/models/sales-models';
import { AuthService } from '../../@auth/auth.service';

@Injectable()
export class SalesService {

    saless: Observable<Array<SalesViewModel>>;
    private _saless: BehaviorSubject<Array<SalesViewModel>>;
    
    private dataStore: {
        saless: Array<SalesViewModel>
    }

    loading: boolean = false;
    query: Query;

    constructor(
        private salesApi: SalesApi,
        private auth: AuthService
        ){

        // This is a pattern I've found really useful, which 
        // is probably like a roll-your-own Redux but just 
        // used for a single aspect (recivals feature)
        this.dataStore = {
            saless: new Array<SalesViewModel>(),
        }    

        this._saless = <BehaviorSubject<SalesViewModel[]>>new BehaviorSubject([]);
        this.saless = this._saless.asObservable();

        this.query = new Query({page:1, size:200});
        this.search();
    }

    // Check we haven't already got this record then
    // add it to the service's dataset. Convert it 
    // from an api object to a view mode;
    // NB: When we have view models on the api, 
    // the response will come back in the right shape and this will not be necessary
    addSales(sales: any) {
        console.log("add sales function")
        if(this.dataStore.saless.findIndex(r => r.palletid === sales.palletid) === -1) {
            this.dataStore.saless.push(
                new SalesViewModel(sales)
            );
        }
    }

    // This isnt currently wired up and is dependent
    // on the api passing back totals.
    pageForward(){
        this.query.page += 1;
        this.search();
    }

    // This isnt currently wired up and is dependent
    // on the api passing back totals.
    pageBack(){
        this.query.page -= 1;
        this.search();
    }

    // calling the api for data, parsing, then updating the subscribers
    // this will be triggered on load, search, and on paging
    search() {
        console.log("search function")
        let fault: boolean = false;
        this.salesApi
            .search(this.query)
            .subscribe(
            // This is the on-success callback
            async saless => {
                let salesGroup: Array<SalesViewModel> = [];
                (await saless as []).forEach(sales => {
                    salesGroup.push(new SalesViewModel(sales));
                });
                salesGroup.forEach(item => this.dataStore.saless.push(item));
                this._saless.next(Object.assign({}, this.dataStore).saless);    
            },
            // If an error happens..
            error => {
                fault = true;
                console.log(error);
            },
            // This always calls on termination 
            // Like the Finally of a try block
            () => {
                // now flush the internal state to the subscribers
                this._saless.next(Object.assign({}, this.dataStore).saless);
                console.log(`Saless loading complete ${ fault ? 'with' : 'without'} fault.`);
            }
        );
    }

    create(sales: SalesCreateRequest) {
        let fault: Boolean = false;
        //sales.User = this.auth.getUser().id;
        this.salesApi.create(sales).subscribe(
            success => {
                console.log(success);
                this.addSales(sales);
            },
            error => {
                console.error(error);
            },
            () => {
                this.loading = false;
                this._saless.next(Object.assign({}, this.dataStore).saless);
                console.log(`Sales creation complete ${ fault ? 'with' : 'without'} fault.`);
            }
        );
    }

    update() {

    }

    delete() {

    }
}