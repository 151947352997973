import { PackagingApi } from './packaging.api';
import { Observable, BehaviorSubject } from 'rxjs';
import * as uuid from 'uuid';
import { Query } from '../../@core/models/query-models';
import { Injectable } from '@angular/core';
import { PalletViewModel, PalletRecord, PalletCreateRequest } from '../../@core/models/pallet-models';
import { AuthService } from '../../@auth/auth.service';

@Injectable()
export class PackagingService {

    packagings: Observable<Array<PalletViewModel>>;
    private _packagings: BehaviorSubject<Array<PalletViewModel>>;

    selectedPackage: Observable<PalletViewModel>;
    private _selectedPackage: BehaviorSubject<PalletViewModel>;
    
    private dataStore: {
        packagings: Array<PalletViewModel>,
        selectedPackage: PalletViewModel
    }

    loading: boolean = false;
    query: Query;

    constructor(
        private packagingApi: PackagingApi,
        private auth: AuthService
        ){

        // This is a pattern I've found really useful, which 
        // is probably like a roll-your-own Redux but just 
        // used for a single aspect (recivals feature)
        this.dataStore = {
            packagings: new Array<PalletViewModel>(),
            selectedPackage: new PalletViewModel()
        }    

        this._packagings = <BehaviorSubject<PalletViewModel[]>>new BehaviorSubject([]);
        this.packagings = this._packagings.asObservable();

        this._selectedPackage = <BehaviorSubject<PalletViewModel>>new BehaviorSubject({});
        this.selectedPackage = this._selectedPackage.asObservable();

        this.query = new Query({page:1, size:200});
        this.search();
    }

    // Check we haven't already got this record then
    // add it to the service's dataset. Convert it 
    // from an api object to a view mode;
    // NB: When we have view models on the api, 
    // the response will come back in the right shape and this will not be necessary
    addPackaging(packaging: any) {
        if(this.dataStore.packagings.findIndex(r => r.palletid === packaging.palletid) === -1) {
            this.dataStore.packagings.push(
                new PalletViewModel(packaging)
            );
        }else{
            console.log("ITEM is already in list, not adding.")
        }
    }

    // This isnt currently wired up and is dependent
    // on the api passing back totals.
    pageForward(){
        this.query.page += 1;
        this.search();
    }

    // This isnt currently wired up and is dependent
    // on the api passing back totals.
    pageBack(){
        this.query.page -= 1;
        this.search();
    }

    // calling the api for data, parsing, then updating the subscribers
    // this will be triggered on load, search, and on paging
    search() {
        let fault: boolean = false;
        this.packagingApi
            .search(this.query)
            .subscribe(
            // This is the on-success callback
            async packagings => {
                let packageGroup: Array<PalletViewModel> = [];
                (await packagings as []).forEach(packaging=> {
                    //this.addPackaging(packaging)
                    packageGroup.push(new PalletViewModel(packaging))
                });    
                packageGroup.forEach(item => this.dataStore.packagings.push(item));
                this._packagings.next(Object.assign({}, this.dataStore).packagings);   
            },
            // If an error happens..
            error => {
                fault = true;
                console.log(error);
            },
            // This always calls on termination 
            // Like the Finally of a try block
            () => {
                // now flush the internal state to the subscribers
                this._packagings.next(Object.assign({}, this.dataStore).packagings);
                console.log(`Packagings loading complete ${ fault ? 'with' : 'without'} fault.`);
            }
        );
    }

    getById(id: string){
        this.packagingApi.get(id).subscribe(async pallet => {
            const palletData = await pallet;
            console.log(palletData)
            this.dataStore.selectedPackage = new PalletViewModel(palletData[0])
            this._selectedPackage.next(Object.assign({}, this.dataStore).selectedPackage)
        })
    }

    create(packaging: PalletCreateRequest) {
        let fault: Boolean = false;
        this.packagingApi.create(packaging).subscribe(
            success => {
                console.log(success);
                this.addPackaging(packaging);
            },
            error => {
                console.error(error);
            },
            () => {
                this.loading = false;
                this._packagings.next(Object.assign({}, this.dataStore).packagings);
                console.log(`Packaging creation complete ${ fault ? 'with' : 'without'} fault.`);
            }
        );
    }

    update() {

    }

    delete() {

    }
}