import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PackhouseService } from './packhouse.service';

@Component({
    selector: 'qualipac-packhouse',
    templateUrl: './packhouse.component.html',
    styles: [`
      nb-card {
        transform: translate3d(0, 0, 0);
      }
    `],
  })

export class PackhouseComponent {
  constructor(
    route: ActivatedRoute,
    packhouse: PackhouseService
    ) {
    route.url.subscribe(segment => {
      console.log('route changed!');
      const currentStep = segment[segment.length - 1].path;
      if(currentStep) packhouse.setCurrentStep(currentStep);
    });
  }
}