import { Injectable } from '@angular/core';
import Auth from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { CognitoUtil } from './cognito.service';

export interface AuthState {
  isLoggedIn: boolean;
  username: string | null;
  name: string | null;
  id: string | null;
  email: string | null;
  idToken: any | null;
}

const initialAuthState = {
  isLoggedIn: false,
  username: null,
  name: null,
  id: null,
  email: null,
  idToken: null
};

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  private readonly _authState = new BehaviorSubject<AuthState>(
    initialAuthState
  ); 

  /** AuthState as an Observable */
  readonly auth$ = this._authState.asObservable();

  /** Observe the isLoggedIn slice of the auth state */
  readonly isLoggedIn$ = this.auth$.pipe(map(state => state.isLoggedIn));

  constructor() {
    // Get the user on creation of this service
    Auth.currentAuthenticatedUser().then(
      (user: any) => this.setUser(user),
      _err => this._authState.next(initialAuthState)
    );

    // Use Hub channel 'auth' to get notified on changes
    Hub.listen('auth', ({ payload: { event, data, message } }) => {
      console.log('Hub Listener', event);
      if (event === 'signIn') {
        // On 'signIn' event, the data is a CognitoUser object
        console.log("TEST TEST");
        this.setUser(data);
      } else if (event === 'signOut'){
        console.log('USER Signed Out');
        this.setUser(data);
      } else {
        this._authState.next(initialAuthState);
      }
    });
  }

  getCredentials() {
    return Auth.currentUserCredentials();
  }

  private setUser(user: any) {
    if (!user) {
      return;
    }

    let idToken = user.signInUserSession.idToken.jwtToken;

    const {
      id, 
      email,
      username,
      name
    } = user;

    console.log('user');
    console.log(user);

    this._authState.next({ isLoggedIn: true, id, username, name, email, idToken });
  }

  getUser() {
    return this._authState.getValue();
  }
}