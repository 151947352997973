import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SalesCreateRequest } from '../../../@core/models/sales-models';
import { SalesService } from '../sales.service';
import { LookupService } from '../../lookup/lookup.service';
import { Router } from '@angular/router';
import { DispatchService } from '../../dispatch/dispatch.service';

@Component({
  selector: 'qualipac-sales-form',
  templateUrl: 'sales-form.html',
  styleUrls: ['sales-form.component.scss']
})

export class SalesFormComponent implements OnInit {

  saleForm: FormGroup;
  salesReceord: SalesCreateRequest;
  _palletId: string;
  constructor(
    private fb: FormBuilder,
    public sales: SalesService,
    public dispatchService: DispatchService,
    public lookup: LookupService,
    private router: Router
    ) {
      this.dispatchService.selectedDispatch.subscribe(data=>{
        console.log("Updating sales form: ", data)
        this._palletId = data.palletid
      })
  }

  ngOnInit(){
    // this.saleForm = this.fb.group({
    //   unitPriceCtrl: '',
    //   rejectedCtrl: '',
    //   excludedFromGPSCtrl: '',
    //   domesticFreightPerUnitCtrl: '',
    //   exportFreightPerUnitCtrl: '',
    //   commissionCtrl: '',
    //   promoChargeCtrl: '',
    // });
  }

  onSubmit() {
    this.saleForm.markAsDirty();
    const newSale = {
      palletid: this._palletId,
      unitPrice: this.saleForm.value.unitPriceCtrl,
      rejected: this.saleForm.value.rejectedCtrl,
      excludedfromgps: this.saleForm.value.excludedFromGPSCtrl,
      domesticfreightperunit: this.saleForm.value.domesticFreightPerUnitCtrl,
      exportfreightperunit: this.saleForm.value.exportFreightPerUnitCtrl,
      commission: this.saleForm.value.commissionCtrl,
      promocharge: this.saleForm.value.promoChargeCtrl
    }
    this.sales.create(new SalesCreateRequest(newSale))
  }
}