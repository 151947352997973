import { Component, ViewChild, Output, OnInit, OnDestroy } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { PackingService, PackhouseRecord } from '../job/packing.service';
import { Ng2SmartTableComponent } from 'ng2-smart-table/ng2-smart-table.component';
import { EventEmitter } from 'events';
import { Subscription } from 'rxjs';
import { PackagingService } from './packaging.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { NbDialogService } from '@nebular/theme';
import { PackhouseService } from '../packhouse/packhouse.service';

@Component({
  selector: 'ngx-packaging',
  templateUrl: './packaging.component.html',
  styles: [`
    nb-card {
      transform: translate3d(0, 0, 0);
    }
  `],
})

export class PackagingComponent implements 
  OnInit, 
  OnDestroy
  {

  /// All these properties setup the Smart Table Component:
  // Let's us catch events if we wire up action buttons such as create etc
  @Output() create: EventEmitter = new EventEmitter();
  // Allows the component source to talk to the dom element ('#table')
  @ViewChild('table') table: Ng2SmartTableComponent;
  // Model for the component
  source: LocalDataSource = new LocalDataSource();
  // UI setup for the component
  settings: {};

  palletId: string;

  //This is for setting the stepper-form stage
  selectedIndex: number = 0
  // listener for the data in the service to update the UI when
  // records are loaded or pushed via notification to the client
  packagingSubscriber: Subscription;
  selectedRowSubscription: Subscription;
  
  constructor(
    private packagingService: PackagingService,
    private translate: TranslateService,
    private router: Router,
    private datepipe: DatePipe,
    private dialogService: NbDialogService,
    private route: ActivatedRoute,
    private packhouse: PackhouseService
  )
  {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => { 
      this.renderSettings();
    });
    this.renderSettings();

    this.packagingSubscriber = this.packagingService.packagings.subscribe(
      packagings => {
        this.source = new LocalDataSource(packagings);
      }
    );

    this.route.url.subscribe(segment => {
      console.log('route changed!');
      const currentStep = segment[segment.length - 1].path;
      if(currentStep) this.packhouse.setCurrentStep(currentStep);
    });
  }

  ngOnInit(){

  }

  ngOnDestroy(){
   
    this.packagingSubscriber.unsubscribe();
  }

  pageForward(){
    this.packagingService.pageForward();
  }

  pageBack(){
    this.packagingService.pageBack();
  }

  rowSelected(palletId:string) {
    console.log('pallet in table selected: ', palletId);
    //this.palletId = palletId
    this.packagingService.getById(palletId)  

  }

  newPackaging(){
    this.router.navigateByUrl("/qualipac/new-packaging");
  }

  // set up the smart table in the ui with links to the model (columns)
  // and action buttons (add/edit etc)
  // pallet for example - is the name of the model propery for the table row.
  /*
  
Purchased
Packed
Cut
Brand
Size
Packaging
Units
  
  */
  renderSettings(){
    this.settings = Object.assign({}, {
      actions: false,
      columns: {
        /* Generated */
        PalletNumber: {
          title: 'Pallet',
          type: 'number',
          filter: true
        },
        /* Date */
        Purchased: {
          title: 'Date',
          filter:true,
          type: 'text'
        },
        /* Lookup */
        Packed: {
          title: 'Packed',
          filter:true,
          type: 'text'
        },
        /* Filtered Lookup - TODO: work out how to filter */
        Cut: {
          title: 'Block',
          type:'string',
          filter:true
        },
        /* Lookup */
        Brand: {
          title: 'Brand',
          type: 'string',
          filter:true
        },
        Size: {
          title: 'Size',
          type: 'string',
          filter:true
        },
        Packaging: {
          title: 'Packaging',
          type: 'string',
          filter:true
        },
        Units: {
          title: 'Variety',
          type: 'string',
          filter:true
        }
      },
      mode: 'external',
      pager: {
        perPage: 20
      }
    });
  }
}