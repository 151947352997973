import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UserLoginService } from "../user-login.service";
import { ChallengeParameters, CognitoCallback, LoggedInCallback } from "../cognito.service";
import { AmplifyService } from 'aws-amplify-angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'awscognito-angular2-app',
    templateUrl: './login.html'
})
export class LoginComponent implements CognitoCallback, LoggedInCallback, OnInit {
    email: string;
    password: string;
    errorMessage: string;
    mfaStep = false;
    mfaData = {
        destination: '',
        callback: null
    };

    constructor(
        public router: Router,
        public userService: UserLoginService,
        public amplifyService: AmplifyService,
        public translate: TranslateService
    ) {
        console.log("LoginComponent constructor");
    }

    ngOnInit() {
        this.errorMessage = null;
        this.userService.isAuthenticated(this);
    }

    onLogin() {
        if (this.email == null || this.password == null) {
            this.errorMessage = this.translate.instant('UX.AUTH.LOGIN.ALLFIELDSREQUIRED');
            return;
        }
        this.errorMessage = null;
        this.userService.authenticate(this.email, this.password, this);
    }

    cognitoCallback(message: string, result: any) {
        if (message != null) { //error
            this.errorMessage = message;
            console.log("result: " + this.errorMessage);
            if (message === 'User is not confirmed.') {
                console.log("redirecting");
                this.router.navigate(['/auth/confirmRegistration', this.email]);
            } else if (message === 'User needs to set password.') {
                this.router.navigate(['/qualipac/newPassword']);
            }
        } else { //success
            console.log('The successsful login result: ', result);
            this.amplifyService.setAuthState(result);
            this.router.navigate(['/qualipac/dashboard']);
        }
    }

    handleMFAStep(challengeName: string, challengeParameters: ChallengeParameters, callback: (confirmationCode: string) => any): void {
        this.mfaStep = true;
        this.mfaData.destination = challengeParameters.CODE_DELIVERY_DESTINATION;
        this.mfaData.callback = (code: string) => {
            if (code == null || code.length === 0) {
                this.errorMessage = this.translate.instant('UX.AUTH.LOGIN.CODEREQUIRED');
                return;
            }
            this.errorMessage = null;
            callback(code);
        };
    }

    isLoggedIn(message: string, isLoggedIn: boolean) {
        if (isLoggedIn) {
            this.router.navigate(['/qualipac/dashboard']);
        }
    }

    cancelMFA(): boolean {
        this.mfaStep = false;
        return false;   //necessary to prevent href navigation
    }
}