import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
/*
Variety -
Cut -
Brand
Size -
Packaging -
UnitsPerPallet 
PalletSpaces 
Supplier 
*/
export class LookupService {
    // properties to listen to the datastore - observers
    varietyObservable: Observable<Array<string>>;
    private _variety: BehaviorSubject<Array<string>>;
    cutObservable: Observable<Array<string>>;
    private _cut: BehaviorSubject<Array<string>>;
    packagingObservable: Observable<Array<string>>;
    private _packaging: BehaviorSubject<Array<string>>;
    supplierObservable: Observable<Array<string>>;
    private _supplier: BehaviorSubject<Array<string>>;
    freighterObservable: Observable<Array<string>>;
    private _freighter: BehaviorSubject<Array<string>>;
    agentObservable: Observable<Array<string>>;
    private _agent: BehaviorSubject<Array<string>>;
    brandObservable: Observable<Array<string>>;
    private _brandSize: BehaviorSubject<Array<string>>;
    sizeObservable: Observable<Array<string>>;
    private _size: BehaviorSubject<Array<string>>;
    unitsPerPalletObservable: Observable<Array<string>>;
    private _unitsPerPallet: BehaviorSubject<Array<string>>;
    palletSpacesObservable: Observable<Array<string>>;
    private _palletSpaces: BehaviorSubject<Array<string>>;

    // state
    private dataStore: {
        variety: Array<string>,
        cut: Array<string>,
        packaging: Array<string>,
        supplier: Array<string>,
        freighter: Array<string>,
        agent: Array<string>,
        brand: Array<string>,
        size: Array<string>,
        unitsPerPallet: Array<string>,
        palletSpaces: Array<string>,
    }

    constructor()
    {
        this.dataStore = {
            variety: new Array<string>(),
            cut: new Array<string>(),
            packaging: new Array<string>(),
            supplier: new Array<string>(),
            freighter: new Array<string>(),
            agent: new Array<string>(),
            brand: new Array<string>(),
            size: new Array<string>(),
            unitsPerPallet: new Array<string>(),
            palletSpaces: new Array<string>()
        }

        // wire up listeners to state
        this._variety = <BehaviorSubject<string[]>>new BehaviorSubject([]);
        this.varietyObservable = this._variety.asObservable();
        this._cut = <BehaviorSubject<string[]>>new BehaviorSubject([]);
        this.cutObservable = this._cut.asObservable();
        this._packaging = <BehaviorSubject<string[]>>new BehaviorSubject([]);
        this.packagingObservable = this._packaging.asObservable();
        this._supplier = <BehaviorSubject<string[]>>new BehaviorSubject([]);
        this.supplierObservable = this._supplier.asObservable();
        this._freighter = <BehaviorSubject<string[]>>new BehaviorSubject([]);
        this.freighterObservable = this._supplier.asObservable();
        this._agent = <BehaviorSubject<string[]>>new BehaviorSubject([]);
        this.agentObservable = this._supplier.asObservable();
        this._brandSize = <BehaviorSubject<string[]>>new BehaviorSubject([]);
        this.brandObservable = this._brandSize.asObservable();
        this._size = <BehaviorSubject<string[]>>new BehaviorSubject([]);
        this.sizeObservable = this._size.asObservable();
        this._unitsPerPallet = <BehaviorSubject<string[]>>new BehaviorSubject([]);
        this.unitsPerPalletObservable = this._unitsPerPallet.asObservable();
        this._palletSpaces = <BehaviorSubject<string[]>>new BehaviorSubject([]);
        this.palletSpacesObservable = this._palletSpaces.asObservable();

        this.populateLookups();
    }

    public get variety(): string[] {
        return this._variety.value;
    }
    
    public get grade(): string[] {
        return this._cut.value;
    }
    
    public get packaging(): string[] {
        return this._packaging.value;
    }

    public get supplier(): string[] {
        return this._supplier.value;
    }

    public get freighter(): string[] {
        return this._freighter.value;
    }

    public get agent(): string[] {
        return this._agent.value;
    }
    
    public get weight(): string[] {
        return this._supplier.value;
    }

    public get rawSize(): string[] {
        return this._brandSize.value;
    }

    public get productSize(): string[] {
        return this._size.value;
    }

    public get origins(): string[] {
        return this._unitsPerPallet.value;
    }

    public get storages(): string[] {
        return this._palletSpaces.value;
    }

    populateLookups(): void {
        // add to the datastore
        this.dataStore.packaging = [
            "SUPPLYCHAIN.HORT.BROCCOLI.PACKAGING.8KGSTYRO",
            "SUPPLYCHAIN.HORT.BROCCOLI.PACKAGING.8KGSTYROCLASS2",
            "SUPPLYCHAIN.HORT.BROCCOLI.PACKAGING.34LWW",
            "SUPPLYCHAIN.HORT.BROCCOLI.PACKAGING.41LCOLES",
            "SUPPLYCHAIN.HORT.BROCCOLI.PACKAGING.41LCOLESFP",
            "SUPPLYCHAIN.HORT.BROCCOLI.PACKAGING.41LWW",
            "SUPPLYCHAIN.HORT.BROCCOLI.PACKAGING.41LWWFP",
            "SUPPLYCHAIN.HORT.BROCCOLI.PACKAGING.450MMBIN",
            "SUPPLYCHAIN.HORT.BROCCOLI.PACKAGING.650MMBIN",
            "SUPPLYCHAIN.HORT.BROCCOLI.PACKAGING.ALDICRATE8KGFP",
            "SUPPLYCHAIN.HORT.BROCCOLI.PACKAGING.ALDICRATE8KG",
            "SUPPLYCHAIN.HORT.BROCCOLI.PACKAGING.ALDICRATE",
            "SUPPLYCHAIN.HORT.BROCCOLI.PACKAGING.ALDICRATEFP",
            "SUPPLYCHAIN.HORT.BROCCOLI.PACKAGING.CHEPBINFB2",
            "SUPPLYCHAIN.HORT.BROCCOLI.PACKAGING.CHEPBINFB2FP",
            "SUPPLYCHAIN.HORT.BROCCOLI.PACKAGING.CHEPBINFB4",
            "SUPPLYCHAIN.HORT.BROCCOLI.PACKAGING.CHEPBINFB4FP",
            "SUPPLYCHAIN.HORT.BROCCOLI.PACKAGING.CHEPBINB7",
            "SUPPLYCHAIN.HORT.BROCCOLI.PACKAGING.EXPORTWAXCTN",
            "SUPPLYCHAIN.HORT.BROCCOLI.PACKAGING.EXPORTWAXCTNFP",
            "SUPPLYCHAIN.HORT.BROCCOLI.PACKAGING.HALFTONNER",
        ];
        // update the observer
        this._packaging.next(Object.assign({}, this.dataStore).packaging);

        this.dataStore.variety = [
            "SUPPLYCHAIN.HORT.BROCCOLI.VARIETY.ANACONDA",
            "SUPPLYCHAIN.HORT.BROCCOLI.VARIETY.ANAPURNA",
            "SUPPLYCHAIN.HORT.BROCCOLI.VARIETY.AURORA",
            "SUPPLYCHAIN.HORT.BROCCOLI.VARIETY.BFC01",
            "SUPPLYCHAIN.HORT.BROCCOLI.VARIETY.BFC02",
            "SUPPLYCHAIN.HORT.BROCCOLI.VARIETY.BFC03",
            "SUPPLYCHAIN.HORT.BROCCOLI.VARIETY.BRAVO",
            "SUPPLYCHAIN.HORT.BROCCOLI.VARIETY.BRUMBY",
            "SUPPLYCHAIN.HORT.BROCCOLI.VARIETY.CASSIUS",
            "SUPPLYCHAIN.HORT.BROCCOLI.VARIETY.KRA01",
            "SUPPLYCHAIN.HORT.BROCCOLI.VARIETY.KRA03",
            "SUPPLYCHAIN.HORT.BROCCOLI.VARIETY.SOLITARE",
            "SUPPLYCHAIN.HORT.BROCCOLI.VARIETY.SPINKS",
            "SUPPLYCHAIN.HORT.BROCCOLI.VARIETY.TERRA2",
            "SUPPLYCHAIN.HORT.BROCCOLI.VARIETY.VIPER"
        ];
        this._variety.next(Object.assign({}, this.dataStore).variety);

        this.dataStore.cut = [
            "SUPPLYCHAIN.HORT.BROCCOLI.CUT.A", 
            "SUPPLYCHAIN.HORT.BROCCOLI.CUT.B", 
            "SUPPLYCHAIN.HORT.BROCCOLI.CUT.C", 
            "SUPPLYCHAIN.HORT.BROCCOLI.CUT.D", 
            "SUPPLYCHAIN.HORT.BROCCOLI.CUT.E"
        ];
        this._cut.next(Object.assign({}, this.dataStore).cut);

        this.dataStore.supplier = [
            "BUSINESS.QUALIPAC.SUPPLIERS.AMIENS",
            "BUSINESS.QUALIPAC.SUPPLIERS.ARAHURAFARMS",
            "BUSINESS.QUALIPAC.SUPPLIERS.BARDENPRODUCE",
            "BUSINESS.QUALIPAC.SUPPLIERS.BERTINAZIFARMS",
            "BUSINESS.QUALIPAC.SUPPLIERS.BRATASHAFARM",
            "BUSINESS.QUALIPAC.SUPPLIERS.CJJAZISCHIE",
            "BUSINESS.QUALIPAC.SUPPLIERS.DOLCLIFFPARTNERSHIP",
            "BUSINESS.QUALIPAC.SUPPLIERS.DOWNSFARMFRESH",
            "BUSINESS.QUALIPAC.SUPPLIERS.DOWNSFARMFRESHBFC",
            "BUSINESS.QUALIPAC.SUPPLIERS.DOWNSFARMFRESHKRA",
            "BUSINESS.QUALIPAC.SUPPLIERS.FINLAYSONPRODUCE",
            "BUSINESS.QUALIPAC.SUPPLIERS.FRAGAPANEFARMS",
            "BUSINESS.QUALIPAC.SUPPLIERS.GASPARINI",
            "BUSINESS.QUALIPAC.SUPPLIERS.GRAHAMDIETE",
            "BUSINESS.QUALIPAC.SUPPLIERS.GREENACRES",
            "BUSINESS.QUALIPAC.SUPPLIERS.GREGLERCH",
            "BUSINESS.QUALIPAC.SUPPLIERS.HARSLETT",
            "BUSINESS.QUALIPAC.SUPPLIERS.HYDROPRODUCE",
            "BUSINESS.QUALIPAC.SUPPLIERS.JENDRAFARM",
            "BUSINESS.QUALIPAC.SUPPLIERS.KELLERORGANICS",
            "BUSINESS.QUALIPAC.SUPPLIERS.KOALAFARM",
            "BUSINESS.QUALIPAC.SUPPLIERS.LAVENDERANDSONS",
            "BUSINESS.QUALIPAC.SUPPLIERS.LINDSAYZISER",
            "BUSINESS.QUALIPAC.SUPPLIERS.LOCKYERPRODUCE",
            "BUSINESS.QUALIPAC.SUPPLIERS.MOIRAFARMING",
            "BUSINESS.QUALIPAC.SUPPLIERS.MOMACKPRODUCE",
            "BUSINESS.QUALIPAC.SUPPLIERS.MOONROCKS",
            "BUSINESS.QUALIPAC.SUPPLIERS.MULGOWIEFARMS",
            "BUSINESS.QUALIPAC.SUPPLIERS.NOLANS",
            "BUSINESS.QUALIPAC.SUPPLIERS.ODEUMPRODUCE",
            "BUSINESS.QUALIPAC.SUPPLIERS.PREMIUMFRESH",
            "BUSINESS.QUALIPAC.SUPPLIERS.QUALIPACAGRICULTURE",
            "BUSINESS.QUALIPAC.SUPPLIERS.QUALIPACFARMS",
            "BUSINESS.QUALIPAC.SUPPLIERS.RUGBYFARM",
            "BUSINESS.QUALIPAC.SUPPLIERS.SHELFORDDOWNS",
            "BUSINESS.QUALIPAC.SUPPLIERS.STEVENALUSH",
            "BUSINESS.QUALIPAC.SUPPLIERS.STEVESIPPEL",
            "BUSINESS.QUALIPAC.SUPPLIERS.SUTTONFARM",
            "BUSINESS.QUALIPAC.SUPPLIERS.TAYLORFARMS",
            "BUSINESS.QUALIPAC.SUPPLIERS.VALLEYFRESH",
            "BUSINESS.QUALIPAC.SUPPLIERS.VANSTONEPRODUCE",
            "BUSINESS.QUALIPAC.SUPPLIERS.WAUCHOPE"
        ];
        this._supplier.next(Object.assign({}, this.dataStore).supplier);

        this.dataStore.freighter = [
            "BUSINESS.QUALIPAC.FREIGHTERS.CTFREIGHT",
            "BUSINESS.QUALIPAC.FREIGHTERS.FRESHFREIGHT",
            "BUSINESS.QUALIPAC.FREIGHTERS.GASPARINI",
            "BUSINESS.QUALIPAC.FREIGHTERS.GRAEMECOX",
            "BUSINESS.QUALIPAC.FREIGHTERS.LINDSAYBROS",
            "BUSINESS.QUALIPAC.FREIGHTERS.NOLANS",
            "BUSINESS.QUALIPAC.FREIGHTERS.PRIVATE",
            "BUSINESS.QUALIPAC.FREIGHTERS.QUALIPACLOGISTICS",
            "BUSINESS.QUALIPAC.FREIGHTERS.RINPRA",
            "BUSINESS.QUALIPAC.FREIGHTERS.SIPPLE",
            "BUSINESS.QUALIPAC.FREIGHTERS.TOLL",
            "BUSINESS.QUALIPAC.FREIGHTERS.TOOHILLS"
        ];
        this._freighter.next(Object.assign({}, this.dataStore).freighter);

        this.dataStore.agent = [
            "BUSINESS.QUALIPAC.AGENTS.AIDREDINTERNATIONAL",
            "BUSINESS.QUALIPAC.AGENTS.AUSTRALIANGLOBALMARKETING",
            "BUSINESS.QUALIPAC.AGENTS.BANCHOON",
            "BUSINESS.QUALIPAC.AGENTS.BARDENPRODUCE",
            "BUSINESS.QUALIPAC.AGENTS.BENELUX",
            "BUSINESS.QUALIPAC.AGENTS.BIGAL",
            "BUSINESS.QUALIPAC.AGENTS.BIGMICHAELS",
            "BUSINESS.QUALIPAC.AGENTS.BIGORANGE",
            "BUSINESS.QUALIPAC.AGENTS.BOKOSFRUIT",
            "BUSINESS.QUALIPAC.AGENTS.BRATASHAFARM",
            "BUSINESS.QUALIPAC.AGENTS.CAMPBELL",
            "BUSINESS.QUALIPAC.AGENTS.CJJAZISCHKE",
            "BUSINESS.QUALIPAC.AGENTS.CMMMARKETING",
            "BUSINESS.QUALIPAC.AGENTS.DESTIES",
            "BUSINESS.QUALIPAC.AGENTS.DOA",
            "BUSINESS.QUALIPAC.AGENTS.DSAFRESH",
            "BUSINESS.QUALIPAC.AGENTS.FOODBANK",
            "BUSINESS.QUALIPAC.AGENTS.FOODWORKS",
            "BUSINESS.QUALIPAC.AGENTS.FRESHMART",
            "BUSINESS.QUALIPAC.AGENTS.FRESHSELECT",
            "BUSINESS.QUALIPAC.AGENTS.FRUITORAMA",
            "BUSINESS.QUALIPAC.AGENTS.GIBBROS",
            "BUSINESS.QUALIPAC.AGENTS.GILLEBRICOTTON",
            "BUSINESS.QUALIPAC.AGENTS.GNLPRODUCE",
            "BUSINESS.QUALIPAC.AGENTS.GREENACRES",
            "BUSINESS.QUALIPAC.AGENTS.GUNDYFRUITVEG",
            "BUSINESS.QUALIPAC.AGENTS.GWIFRUITS",
            "BUSINESS.QUALIPAC.AGENTS.HARROWSMITHS",
            "BUSINESS.QUALIPAC.AGENTS.HARVESTFRESHCUTS",
            "BUSINESS.QUALIPAC.AGENTS.HEHEATHER",
            "BUSINESS.QUALIPAC.AGENTS.HERITAGEPRODUCTS",
            "BUSINESS.QUALIPAC.AGENTS.HULEE",
            "BUSINESS.QUALIPAC.AGENTS.HUNTERFRESHPRODUCE",
            "BUSINESS.QUALIPAC.AGENTS.HYDROPRODUCE",
            "BUSINESS.QUALIPAC.AGENTS.IGAFRESH",
            "BUSINESS.QUALIPAC.AGENTS.ITSFRESH",
            "BUSINESS.QUALIPAC.AGENTS.JONSSONSFARM",
            "BUSINESS.QUALIPAC.AGENTS.LAMANNA",
            "BUSINESS.QUALIPAC.AGENTS.LAVENDERSONS",
            "BUSINESS.QUALIPAC.AGENTS.LOCKYERPRODUCE",
            "BUSINESS.QUALIPAC.AGENTS.MCGLOBAL",
            "BUSINESS.QUALIPAC.AGENTS.MIJUFRESH",
            "BUSINESS.QUALIPAC.AGENTS.MMRPRODUCE",
            "BUSINESS.QUALIPAC.AGENTS.MONTAGUEFRESH",
            "BUSINESS.QUALIPAC.AGENTS.MULGOWIE",
            "BUSINESS.QUALIPAC.AGENTS.NTUC",
            "BUSINESS.QUALIPAC.AGENTS.OTOOLPRODUCE",
            "BUSINESS.QUALIPAC.AGENTS.PERFECTIONFRESH",
            "BUSINESS.QUALIPAC.AGENTS.PMFRESH",
            "BUSINESS.QUALIPAC.AGENTS.PREMIERFRUITS",
            "BUSINESS.QUALIPAC.AGENTS.QCFRESH",
            "BUSINESS.QUALIPAC.AGENTS.RATTRADING",
            "BUSINESS.QUALIPAC.AGENTS.RICHLANDS",
            "BUSINESS.QUALIPAC.AGENTS.SAJEEXPORT",
            "BUSINESS.QUALIPAC.AGENTS.SAMSONSFRUITVEG",
            "BUSINESS.QUALIPAC.AGENTS.SCALZIPRODUCE",
            "BUSINESS.QUALIPAC.AGENTS.SCHULTES",
            "BUSINESS.QUALIPAC.AGENTS.SHINYAOYA",
            "BUSINESS.QUALIPAC.AGENTS.SIMPLOT",
            "BUSINESS.QUALIPAC.AGENTS.STUARTDICKSONPRODUCE",
            "BUSINESS.QUALIPAC.AGENTS.SUTTONFRESH",
            "BUSINESS.QUALIPAC.AGENTS.TFMARKETING",
            "BUSINESS.QUALIPAC.AGENTS.THEBIGORANGE",
            "BUSINESS.QUALIPAC.AGENTS.THEGREATAUSTRALIANBITE",
            "BUSINESS.QUALIPAC.AGENTS.TIMSHENG",
            "BUSINESS.QUALIPAC.AGENTS.TOTALFOODNETWORK",
            "BUSINESS.QUALIPAC.AGENTS.TROPICALFRUITS",
            "BUSINESS.QUALIPAC.AGENTS.UNION",
            "BUSINESS.QUALIPAC.AGENTS.VEGIPREPI",
            "BUSINESS.QUALIPAC.AGENTS.VELIVELISHA",
            "BUSINESS.QUALIPAC.AGENTS.VICTORYFRESH",
            "BUSINESS.QUALIPAC.AGENTS.WATTEXPORT",
            "BUSINESS.QUALIPAC.AGENTS.WESTRIDGE",
            "BUSINESS.QUALIPAC.AGENTS.WOOLWORTHS",
            "BUSINESS.QUALIPAC.AGENTS.WYALLAFRUITANDVEG"
        ];
        this._agent.next(Object.assign({}, this.dataStore).agent);

        this.dataStore.brand = [
            "SUPPLYCHAIN.HORT.BROCCOLI.BRAND.ALDIRPC",
            "SUPPLYCHAIN.HORT.BROCCOLI.BRAND.BROCCOLICLASS2",
            "SUPPLYCHAIN.HORT.BROCCOLI.BRAND.BROCCOLIPREMIUM",
            "SUPPLYCHAIN.HORT.BROCCOLI.BRAND.CLASS2",
            "SUPPLYCHAIN.HORT.BROCCOLI.BRAND.COLESRPC",
            "SUPPLYCHAIN.HORT.BROCCOLI.BRAND.FOODBANK",
            "SUPPLYCHAIN.HORT.BROCCOLI.BRAND.FRESHMART",
            "SUPPLYCHAIN.HORT.BROCCOLI.BRAND.GENERIC",
            "SUPPLYCHAIN.HORT.BROCCOLI.BRAND.GIVVO",
            "SUPPLYCHAIN.HORT.BROCCOLI.BRAND.GLENCOREGROVEFARMS",
            "SUPPLYCHAIN.HORT.BROCCOLI.BRAND.ICEPACK",
            "SUPPLYCHAIN.HORT.BROCCOLI.BRAND.LUCKYLIZARD",
            "SUPPLYCHAIN.HORT.BROCCOLI.BRAND.MONTAGUE",
            "SUPPLYCHAIN.HORT.BROCCOLI.BRAND.NOLABEL",
            "SUPPLYCHAIN.HORT.BROCCOLI.BRAND.PERFECTLYIMPERFECT",
            "SUPPLYCHAIN.HORT.BROCCOLI.BRAND.PREMIUM",
            "SUPPLYCHAIN.HORT.BROCCOLI.BRAND.QLDFRESHEXPORTS",
            "SUPPLYCHAIN.HORT.BROCCOLI.BRAND.QUALIPAC",
            "SUPPLYCHAIN.HORT.BROCCOLI.BRAND.QUALIPACGENERIC",
            "SUPPLYCHAIN.HORT.BROCCOLI.BRAND.SEASONSHARVEST",
            "SUPPLYCHAIN.HORT.BROCCOLI.BRAND.STUARTDICKSON",
            "SUPPLYCHAIN.HORT.BROCCOLI.BRAND.WATTEXPORT"
        ];
        this._brandSize.next(Object.assign({}, this.dataStore).brand);

        this.dataStore.size = [
            "SUPPLYCHAIN.HORT.BROCCOLI.SIZE.EXPORT",
            "SUPPLYCHAIN.HORT.BROCCOLI.SIZE.LARGE",
            "SUPPLYCHAIN.HORT.BROCCOLI.SIZE.MEDIUM",
            "SUPPLYCHAIN.HORT.BROCCOLI.SIZE.SMALL",
            "SUPPLYCHAIN.HORT.BROCCOLI.SIZE.VARIOUS"
        ];
        this._size.next(Object.assign({}, this.dataStore).size);

        this.dataStore.unitsPerPallet = [
            "SUPPLYCHAIN.HORT.BROCCOLI.UNITSPERPALLET.40",
            "SUPPLYCHAIN.HORT.BROCCOLI.UNITSPERPALLET.60"
        ];
        this._unitsPerPallet.next(Object.assign({}, this.dataStore).unitsPerPallet);

        this.dataStore.palletSpaces = [
            "SUPPLYCHAIN.HORT.BROCCOLI.SPACESPERPALLET.HALFPALLET",
            "SUPPLYCHAIN.HORT.BROCCOLI.SPACESPERPALLET.FULLPALLET"
        ];
        this._palletSpaces.next(Object.assign({}, this.dataStore).palletSpaces);
    }
}