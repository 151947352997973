// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:b5614ad9-6eb3-4cab-87c7-8aa6f7ab1d6b",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_wozEBHomu",
    "aws_user_pools_web_client_id": "7o29782ncveitju5s4uk7hfkje",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "qualipacApi",
            "endpoint": "https://0hcvvf4sa7.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        }
    ],
    "aws_content_delivery_bucket": "qualipac-20191106162526-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "ap-southeast-2",
    "aws_content_delivery_url": "http://qualipac-20191106162526-hostingbucket-dev.s3-website-ap-southeast-2.amazonaws.com"
};


export default awsmobile;
