import * as uuid from 'uuid';
import { BoxCreateRequest, BoxViewModel, BoxRecord } from './box-models';
import { basename } from 'path';

// raw from api
export class PalletRecord {
    constructor(init?:any) {
        if(init){
            this.PalletId = init.palletId;
            this.PalletNumber = init.palletNumber;
            this.boxes = init.boxes;
        }
    }

    PalletId: string;
    PalletNumber: string;
    boxes: Array<BoxRecord>;
}

// use this in the view
export class PalletViewModel {
    constructor(init?:any) {
        if(init){
            this.palletid = init.palletid;
            this.packingid = init.packingid;
            this.dispatchid = init.dispatchid;
            this.saleid = init.saleid;
            this.settlementid = init.settlementid;
            this.barcode = init.barcode;
            this.created = init.created;
            this.palletnumber = init.palletnumber;
        }
    }
    palletid: string;
    packingid: string;
    dispatchid: string;
    saleid: string;
    settlementid: string;
    barcode: string;
    created: string;
    palletnumber: string;
}

// post/put to the server
// If it's an update, it will have a PalletId, other wise its a create
// so we generate the PalletId client side
// NB: When we have an API that returns the created record, we'll create
// the ids on the API, not the client.
export class PalletCreateRequest {
    constructor(init?:any) {
        if(init){
            this.palletid = init.palletid;
            this.packingid = init.packingid || uuid.v4();
            this.dispatchid = init.dispatchid;
            this.saleid = init.saleid;
            this.settlementid = init.settlementid;
            this.barcode = init.barcode;
            this.created = init.created;
            this.palletnumber = init.palletnumber;
        }
        else {
            this.packingid = uuid.v4();
        }
    }
    palletid: string;
    packingid: string;
    dispatchid: string;
    saleid: string;
    settlementid: string;
    barcode: string;
    created: string;
    palletnumber: string;
}

// response - not necessary in this version of the API
export class PalletCreateResponse {
    
}