import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SettlementCreateRequest } from '../../../@core/models/settlement-models';
import { SettlementService } from '../settlement.service';
import { Router } from '@angular/router';
import { LookupService } from '../../lookup/lookup.service';

@Component({
  selector: 'qualipac-settlement-form',
  templateUrl: 'settlement-form.html',
  styleUrls: ['settlement-form.component.scss']
})

export class SettlementFormComponent implements OnInit {

  settlementForm: FormGroup;
  settlementRecord: SettlementCreateRequest;

  constructor(
    private fb: FormBuilder,
    public settlement: SettlementService,
    public lookup: LookupService,
    private router: Router
    ) {

  }

  ngOnInit(){
    this.settlementForm = this.fb.group({
      unitPriceCtrl: '',
      rejectedCtrl:'',
      excludeFromGps:'',
      domesticFreightPerUnitCtrl:'',
      exportFreightPerUnitCtrl:'',
      totalFreightPerUnitCtrl:'',
      commissionCtrl:'',
      rebateCtrl:'',
      promoChargeCtrl:'',
      unitPriceToGrowerCtrl:'',
      kgPriceToGrowerCtrl:'',
      gpsCtrl:'',
      federalLevyPerPalletCtrl:'',
      finalKgPriceToGrowerCtrl:'',
      farmgatePriceCtrl:'',
      cosCtrl:'',
      purchasedPricePerKgCtrl:'',
      growerPrepaymentUnitPriceCtrl:'',
      marketProfitCtrl:'',
      marginCtrl:''
    });
  }

  onSubmit() {
    this.settlementForm.markAsDirty();
    this.settlement.create(this.settlementRecord);
    this.router.navigateByUrl("/settlements");
  }
}