import { NgModule } from '@angular/core';
import { NgxEchartsModule } from 'ngx-echarts';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ThemeModule } from '../../@theme/theme.module';
import { DashboardComponent } from './dashboard.component';
import { ChartModule } from 'angular2-chartjs';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { AmplifyAngularModule } from 'aws-amplify-angular';
import { QualiapcInventoryPieChartComponent } from './inventory-charts/qualipac-inventory.component'

@NgModule({
  imports: [
    ThemeModule,
    ChartModule,
    NgxEchartsModule,
    NgxChartsModule,
    LeafletModule,
    TranslateModule,
    AmplifyAngularModule
  ],
  declarations: [
    DashboardComponent,
    QualiapcInventoryPieChartComponent
  ],
  providers: [
    TranslateService
  ],
})
export class DashboardModule { }