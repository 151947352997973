import { NgModule } from '@angular/core';
import { QualipacComponent } from './qualipac.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { QualipacRoutingModule } from './qualipac-routing.module';
import { ThemeModule } from '../@theme/theme.module';
import { PackingModule } from './job/packing.module';
import { TranslateModule } from '@ngx-translate/core';
import { DispatchModule } from './dispatch/dispatch.module';
import { MiscellaneousModule } from '../@theme/miscellaneous/miscellaneous.module';
import { LookupService } from './lookup/lookup.service';
import { SalesModule } from './sales/sales.module';
import { ReceivalModule } from './receival/receival.module';
import { SettlementModule } from './settlement/settlement.module';
import { PackagingModule } from './packaging/packaging.module';
import { PackhouseComponent } from './packhouse/packhouse.component';
import { PackhouseFormComponent } from './packhouse/packhouse-form/packhouse-form.component';
import { AccountingComponent } from './accounting/accounting.component';
import { AccountingFormComponent } from './accounting/accounting-form/accounting-form.component';
import { PackhouseService } from './packhouse/packhouse.service';
const QUALIPAC_COMPONENTS = [
  QualipacComponent,
];

@NgModule({
  imports: [
    QualipacRoutingModule,
    ThemeModule,
    MiscellaneousModule,
    DashboardModule,
    DispatchModule,
    TranslateModule,
    SalesModule,
    SettlementModule,
    ReceivalModule,
    PackingModule,
    PackagingModule
  ],
  declarations: [
    ...QUALIPAC_COMPONENTS,
    PackhouseComponent,
    PackhouseFormComponent,
    AccountingComponent,
    AccountingFormComponent
  ],
  providers: [
    LookupService,
    PackhouseService
  ],
  exports: [
  ]
})

export class QualipacModule {

}