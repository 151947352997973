import * as uuid from 'uuid';

// raw from api
export class SettlementRecord {
    constructor(init?:any) {
        if(init){
            this.PalletId = init.palletId;
            this.Block = init.block;
            this.Date = init.date;
            this.Variety = init.variety;
            this.Supplier = init.supplier;
        }
    }

    PalletId: string;
    PalletNumber: string;
    Date: string;
    Block: string;
    Variety: string;
    Supplier: string;
}

// use this in the view
export class SettlementViewModel {
    constructor(init?:any) {
        if(init){
            this.PalletId = init.palletId;
            this.Block = init.block;
            this.Date = init.date;
            this.Variety = init.variety;
            this.Supplier = init.supplier;
        }
    }
    PalletId: string;
    PalletNumber: string;
    Date: string;
    Block: string;
    Variety: string;
    Supplier: string;
}

// post/put to the server
// If it's an update, it will have a PalletId, other wise its a create
// so we generate the PalletId client side
// NB: When we have an API that returns the created record, we'll create
// the ids on the API, not the client.
export class SettlementCreateRequest {
    constructor(init?:any) {
        if(init){
            this.PalletId = init.palletId;
            this.Block = init.block;
            this.Date = init.date;
            this.Variety = init.variety;
            this.Supplier = init.supplier;
        }
        else {
            this.PalletId = uuid.v4();
        }
    }
    PalletId: string;
    Date: string;
    Block: string;
    Variety: string;
    Supplier: string;
    User: string;
}

// response - not necessary in this version of the API
export class SettlementCreateResponse {
    
}