import {Component} from "@angular/core";
import {Router} from "@angular/router";
import {UserRegistrationService} from "../user-registration.service";
import {CognitoCallback} from "../cognito.service";
import { RegistrationUser } from './registration.user';
import { TranslateService } from '@ngx-translate/core';



/**
 * This component is responsible for displaying and controlling
 * the registration of the user.
 */

 @Component({
    selector: 'awscognito-angular2-app',
    templateUrl: './registration.html'
})

export class RegisterComponent implements CognitoCallback {
    registrationUser: RegistrationUser;
    router: Router;
    errorMessage: string;

    constructor(
        public userRegistration: UserRegistrationService, 
        router: Router,
        public translate: TranslateService
        ) {
        this.router = router;
        this.onInit();
    }

    onInit() {
        this.registrationUser = new RegistrationUser();
        this.errorMessage = null;
    }

    onRegister() {
        this.errorMessage = null;
        this.userRegistration.register(this.registrationUser, this);
    }

    cognitoCallback(message: string, result: any) {
        if (message != null) { //error
            this.errorMessage = message;
            console.log("result: " + this.errorMessage);
        } else { //success
            //move to the next step
            console.log("redirecting");
            this.router.navigate(['/auth/confirmRegistration', result.user.username]);
        }
    }
}