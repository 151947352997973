import * as uuid from 'uuid';

// raw from api
export class ReceivalRecord {
    constructor(init?:any) {
        if(init){
            this.PalletId = init.PalletId;
            this.Block = init.Block;
            this.Date = init.Date;
            this.Variety = init.Variety;
            this.Supplier = init.Supplier;
        }
    }

    PalletId: string;
    PalletNumber: string;
    Date: string;
    Block: string;
    Variety: string;
    Supplier: string;
}

// use this in the view
export class ReceivalViewModel {
    constructor(init?:any) {
        if(init){
            this.PalletId = init.PalletId;
            this.Block = init.Block;
            this.Date = init.Date;
            this.Variety = init.Variety;
            this.Supplier = init.Supplier;
        }
    }
    // These properties encapsulate data from the server
    PalletId: string;
    PalletNumber: string;
    Date: string;
    Block: string;
    Variety: string;
    Supplier: string;

    // These properties encapsulate translated data for the view
    // anything that is translateable should have a corresponding label
    date_label: string;
    variety_label: string;
    supplier_label: string;
}

// post/put to the server
// If it's an update, it will have a PalletId, other wise its a create
// so we generate the PalletId client side
// NB: When we have an API that returns the created record, we'll create
// the ids on the API, not the client.
export class ReceivalCreateRequest {
    
    constructor(init?:any) {
        if(init){
            this.receivalid = init.palletId || uuid.v4();
            this.palletid = init.palletId
            this.block = init.block;
            this.date = init.date;
            this.variety = init.variety;
            this.supplier = init.supplier;
            this.palletnumber = init.PalletNumber
        }
        else {
            this.palletid = uuid.v4();
        }
    }
    receivalid: string;
    palletid: string;
    date: string;
    block: string;
    variety: string;
    supplier: string;
    palletnumber: string;
    //User: string;
}

// response - not necessary in this version of the API
export class ReceivalCreateResponse {
    
}