import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'ngx-packing-receival-placeholder',
  templateUrl: 'packing-receival-placeholder.component.html',
  styleUrls: ['packing-receival-placeholder.component.scss'],
})
export class PackingReceivalPlaceholderComponent {

  @HostBinding('attr.aria-label')
  label = 'Loading';
}
