import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import * as uuid from 'uuid';
import { DateFormatPipe } from '../../@theme/pipes/dateFormat.pipe';

const TOTAL_PAGES = 100;

export class Pallet {

  state: string;
  receival: Receival;
  dispatch: Dispatch;
  payment: Payment;
}

export class Receival {
  pallet: string;
  date: string;
  block: string;
  variety: string;
  supplier: string;
  purchased: string;
  packed: string;
  cut: string;
  brand: string;
  size: string;
  packaging: string;
  dispatched: string;
  delivered: string;
  reference: string;
  freighter: string;
  payfreight: string;
  agent: string;
  export: string;
  destination: string;
}

export class Dispatch {

}

export class Payment {

}

export class PackhouseRecord {

  /* Inventory attributes
  - id
  - date
  - packed
  - purchased
  - container { 
      id, 
      label, 
      type 
    }
  - product {
      commodity {
        id,
        name,
        variety,
        supplychain
      }
      packaging {
        id,
        name,
        description,
        brand
      }, 
      units,
      cut,
      size
    }
  - supplier {
      id,
      name
    }
  - customer {
      id,
      name
    }
  - dispatch {
      dispached,
      delivered,
      reference,
      freighter {
        id,
        name
      }
      freightPayment,
      agent {
        id,
        name
      },
      export,
      destination
    }
  - settlement {
      unitPrice,
      rejected,
      excludedFromGps,
      domesticFreightPerUnit,
      exportFreightPerUnit,
      totalFreightPerUnit,
      commission,
      rebate,
      promoCharge,
      unitPriceToGrower,
      kgPriceToGrower,
      Gps,
      federalLevyPerPallet,
      finalKgPriceToGrower,
      farmGatePrice,
      cos,
      purchasedPriceKg,
      growerPrePaymentUnitPrice,
      marketProfit,
      margin
  }
  
  */
  id: string;
  date: string;
  packed: boolean;
  purchased: boolean; 
  pallet: string;

  /* Supplier attributes
  - supplier { id, name }
  - 
  */
  supplier: SupplierRecord;

  /* Product attributes */
  product: ProductRecord;

  /* Customer attributes */
  customer: CustomerRecord;

  /* Order dispatch attributes */
  dispatch: DispatchRecord;

  /* Order freighter attributes */
  freighter: FreightRecord;

  flatten() : any {
    return {
      pallet: this.pallet,
      date: this.date,
      block: this.supplier.block,
      variety: this.product.commodity.variety,
      supplier: this.supplier.name,
      sold: this.purchased,
      packed: this.packed,
      cut: this.product.cut,
      brand: this.product.brand,
      size: this.product.size,
      packaging: this.product.packaging,
      units: 0
    };
  }
}

export class SupplierRecord {
  id: string;
  name: string;
  brand: string;
  block: string;
  commodity: CommodityRecord;
}

export class FreightRecord {
  id: string;
  name: string;
}

export class DispatchRecord {
  id: string;
  dispatched: string;
  delivered: string;
  reference: string;
  export: string;
  destination: string;
}

export class CustomerRecord {
  id: string;
  name: string;
}

export class ProductRecord {
  id: string;
  brand: string;
  packaging: string;
  commodity: CommodityRecord;
  packed: boolean;
  cut: string;
  size: string;
}

export class CommodityRecord {
  id: string;
  name: string;
  variety: string;
  supplychain: string;
}

export class PackagingTypeRecord {
  id: string;
  name: string;
  kgs: number;
  /* packaging costs ex GST */
  cost:number;
  /* qualipac Ag pre-payment for unit */
  prepayment:number;
}

@Injectable()
export class PackingService {

  products: Observable<Array<ProductRecord>>;
  private _products: BehaviorSubject<Array<ProductRecord>>;

  commodities: Observable<Array<CommodityRecord>>;
  private _commodities: BehaviorSubject<Array<CommodityRecord>>;

  packagingTypes: Observable<Array<PackagingTypeRecord>>;
  private _packagingTypes: BehaviorSubject<Array<PackagingTypeRecord>>;

  customers: Observable<Array<CustomerRecord>>;
  private _customers: BehaviorSubject<Array<CustomerRecord>>;

  dispatches: Observable<Array<DispatchRecord>>;
  private _dispatches: BehaviorSubject<Array<DispatchRecord>>;

  freighters: Observable<Array<FreightRecord>>;
  private _freighters: BehaviorSubject<Array<FreightRecord>>;

  suppliers: Observable<Array<SupplierRecord>>;
  private _suppliers: BehaviorSubject<Array<SupplierRecord>>;

  qualipacRecords: Observable<Array<PackhouseRecord>>;
  private _qualipacRecords: BehaviorSubject<Array<PackhouseRecord>>;

  flattened: Observable<Array<any>>;
  private _flattened: BehaviorSubject<Array<any>>;

  private dataStore: {
    products: Array<ProductRecord>,
    commodities: Array<CommodityRecord>,
    packagingTypes: Array<PackagingTypeRecord>,
    customers: Array<CustomerRecord>,
    dispatches: Array<DispatchRecord>,
    freighters: Array<FreightRecord>,
    suppliers: Array<SupplierRecord>,
    qualipacRecords: Array<PackhouseRecord>,
    flattened: Array<any>
  }

  dateFormatPipe:DateFormatPipe = new DateFormatPipe('en-UK');

  constructor(private http: HttpClient) 
  {
      this.dataStore = {
        products: new Array<ProductRecord>(),
        commodities: new Array<CommodityRecord>(),
        packagingTypes: new Array<PackagingTypeRecord>(),
        customers: new Array<CustomerRecord>(),
        dispatches: new Array<DispatchRecord>(),
        freighters: new Array<FreightRecord>(),
        suppliers: new Array<SupplierRecord>(),
        qualipacRecords: new Array<PackhouseRecord>(),
        flattened: new Array<any>()
      };
  
      this._products = <BehaviorSubject<ProductRecord[]>>new BehaviorSubject([]);
      this.products = this._products.asObservable();
  
      this._commodities = <BehaviorSubject<CommodityRecord[]>>new BehaviorSubject([]);
      this.commodities = this._commodities.asObservable();

      this._packagingTypes = <BehaviorSubject<PackagingTypeRecord[]>>new BehaviorSubject([]);
      this.packagingTypes = this._packagingTypes.asObservable();
  
      this._customers = <BehaviorSubject<CustomerRecord[]>>new BehaviorSubject([]);
      this.customers = this._customers.asObservable();
  
      this._dispatches = <BehaviorSubject<DispatchRecord[]>>new BehaviorSubject([]);
      this.dispatches = this._dispatches.asObservable();
  
      this._freighters = <BehaviorSubject<FreightRecord[]>>new BehaviorSubject([]);
      this.freighters = this._freighters.asObservable();
  
      this._suppliers = <BehaviorSubject<SupplierRecord[]>>new BehaviorSubject([]);
      this.suppliers = this._suppliers.asObservable();
  
      this._qualipacRecords = <BehaviorSubject<PackhouseRecord[]>>new BehaviorSubject([]);
      this.qualipacRecords = this._qualipacRecords.asObservable();

      this._flattened = <BehaviorSubject<any[]>>new BehaviorSubject([]);
      this.flattened = this._flattened.asObservable();

      const path = 'assets/data/packing.json'
      this.importReceivals(path);
  }

  load(page: number, pageSize: number): Observable<PackhouseRecord[]> {
    console.log('packing service loading');
    const startIndex = ((page - 1) % TOTAL_PAGES) * pageSize;
    return this.flattened
      .pipe(
        map(records => records.splice(startIndex, pageSize))
      );
  }

  public get qualipacRecordsValue(): PackhouseRecord[] {
    return this._qualipacRecords.value;
  }

  public get customersValue(): CustomerRecord[] {
    return this._customers.value;
  }

  public get productsValue(): ProductRecord[] {
    return this._products.value;
  }

  public get commoditiesValue(): CommodityRecord[] {
    return this._commodities.value;
  }

  public get PackagingTypesValue(): PackagingTypeRecord[] {
    return this._packagingTypes.value;
  }

  public get dispatchesValue(): DispatchRecord[] {
    return this._dispatches.value;
  }

  public get freightersValue(): FreightRecord[] {
    return this._freighters.value;
  }

  public get suppliersValue(): SupplierRecord[] {
    return this._suppliers.value;
  }

  public get cutsValue(): string[] {
    return ["A","B","C","D","E"];
  }

  public get freightPaymentsValue(): string[]{
    return ["Agent", "Qualipac", "Supplier"];
  }

  public get brandsValue(): string[] {
    return [
      "Broccoli (Class 2)",
      "Broccoli (Premium)",
      "ALDI RPC",
      "Class 2",
      "Coles RPC",
      "Food Bank",
      "Freshmart",
      "Generic",
      "Givvo",
      "Glenore Grove Farms",
      "Ice Pack",
      "Lucky Lizard",
      "Montague",
      "No Label",  
      "Perfectly Imperfect",
      "Premium",
      "QLD Fresh Exports",
      "Qualipac",
      "Qualipac Generic",
      "Season's Harvest",
      "Stuart Dickson",
      "Watt Export",
      "Woolworths RPC",
      "Woolworths RPC (Aldi)"
    ];
  }

  public get packagingValue(): string[] {
    return [
      "34L WW",
      "41L Coles",
      "41L Coles FP",
      "41L WW",
      "41L WW FP",
      "450mm Bin",
      "48L WW",
      "48L WW FP",
      "650mm Bin",
      "8kg Carton",
      "8kg Styro",
      "8kg Styro (Class 2)",
      "Aldi Crate",
      "Aldi Crate FP",
      "Chep Bin (FB2)",
      "Chep Bin (FB2) FP",
      "Chep Bin (FB4)",
      "Chep Bin (FB4) FP",
      "Chep Bin (PB7)",
      "Export Wax Ctn",
      "Export Wax Ctn FP",
      "Half Tonner"
    ];
  }

  public get destinationValue(): string[] {
    return [
      "ADEL",
      "BRIS",
      "BRIS/T'VILLE",
      "CAIRNS",
      "FARMGATE",
      "FOREST HILL",
      "GATTON",
      "GOONDIWINDI",
      "KILLARNEY",
      "LARAPINTA",
      "MELB",
      "MINCHINBURY",
      "MULGRAVE",
      "N'CSTLE",
      "NSW",
      "PERTH",
      "SILVERWATER",
      "SYD",
      "T'VILLE",
      "TWMBA",
      "TAS",
      "VIC",
      "WA",
      "WODONGA",
      "WYONG"
    ];
  }

  public get sizesValue(): string[] {
    return ["S", "M", "L", "Export", "Various"];
  }

  importReceivals(path: string) {
    console.log(`Doing import for ${path}`);
    try {
      this.import(path).subscribe(receivals => receivals
          .map(receival => {
            this.dataStore.flattened.push(receival);
            this.transform(receival);
          }
        )
      );
    }
    catch(error) {
      console.log(error);
    }
    finally {
      this._flattened.next(Object.assign({}, this.dataStore).flattened);
    }
  }

  import(path: string) {
    console.log(`loading file: ${path}`);
    return this.http.get<Receival[]>(path);
  }

  transform(receival: Receival) {

    let qualipac = new PackhouseRecord();

    try{
      qualipac.id = uuid.v4();
      qualipac.date = receival.date;
      qualipac.packed = receival.packed === 'Packed' ? true : false;
      qualipac.purchased = receival.purchased === 'Purchased' ? true : false;
      qualipac.pallet = receival.pallet;

      qualipac.supplier = this.getSupplier(receival);
      qualipac.product = this.getProduct(receival);
      qualipac.customer = this.getCustomer(receival);
      qualipac.dispatch = this.getDispatch(receival);
      qualipac.freighter = this.getFreighter(receival);

      this.dataStore.qualipacRecords.push(qualipac);
      
    }
    catch (error){
      console.log(error);
    }
    finally {
      this._qualipacRecords.next(Object.assign({}, this.dataStore).qualipacRecords);
    }
  }

  getSupplier(receival: Receival) : SupplierRecord {
    let supplier = this.dataStore.suppliers.find(supplier => supplier.name === receival.supplier);
    
    if(!supplier) {
      supplier = new SupplierRecord();
      supplier.id = uuid.v4();
      supplier.name = receival.supplier;
      supplier.block = receival.block;
      supplier.brand = receival.brand;
      supplier.commodity = this.getCommodity(receival);

      this.dataStore.suppliers.push(supplier);
      this._suppliers.next(Object.assign({}, this.dataStore).suppliers); 
    }

    return supplier;
  }

  getProduct(receival: Receival) : ProductRecord {
    let product = this.dataStore.products.find(product => product.brand === receival.brand);

    if(!product) {
      product = new ProductRecord();
      product.id = uuid.v4();
      product.brand = receival.brand;
      product.commodity = this.getCommodity(receival);
      product.cut = receival.cut;
      product.packed = receival.packed === 'Packed' ? true: false;
      product.packaging = receival.packaging;
      product.size = receival.size;

      this.dataStore.products.push(product);
      this._products.next(Object.assign({}, this.dataStore).products); 
    }

    return product;
  }

  getCustomer(receival: Receival) : CustomerRecord {
    let customer = this.dataStore.customers.find(customer => customer.name === receival.agent);

    if(!customer) {
      customer = new CustomerRecord();
      customer.id = uuid.v4();
      customer.name = receival.agent;

      this.dataStore.customers.push(customer);
      this._customers.next(Object.assign({}, this.dataStore).customers); 
    }

    return customer;
  }

  getDispatch(receival: Receival) : DispatchRecord {
    let dispatch = this.dataStore.dispatches.find(dispatch => dispatch.reference === receival.reference);

    if(!dispatch) {
      dispatch = new DispatchRecord();
      dispatch.id = uuid.v4();
      dispatch.reference = receival.reference;
      dispatch.export = receival.export;
      dispatch.dispatched = receival.dispatched;
      dispatch.destination = receival.destination;
      dispatch.delivered = receival.delivered;

      this.dataStore.dispatches.push(dispatch);
      this._dispatches.next(Object.assign({}, this.dataStore).dispatches); 
    }

    return dispatch;
  }

  getFreighter(receival: Receival) : FreightRecord {
    let freighter = this.dataStore.freighters.find(freighter => freighter.name === receival.freighter);

    if(!freighter) {
      freighter = new FreightRecord();
      freighter.id = uuid.v4();
      freighter.name = receival.freighter;  

      this.dataStore.freighters.push(freighter);
      this._freighters.next(Object.assign({}, this.dataStore).freighters); 
    }

    return freighter;
  }

  getCommodity(receival: Receival) : CommodityRecord {
    let commodity = this.dataStore.commodities.find(commodity => commodity.variety === receival.variety);

    if(!commodity) {
      commodity = new CommodityRecord();
      commodity.id = uuid.v4();
      commodity.name = "Broccoli";
      commodity.variety = receival.variety
      this.dataStore.commodities.push(commodity);
      this._commodities.next(Object.assign({}, this.dataStore).commodities); 
    }

    return commodity;
  }

  getNextPalletNumber(): number {
    let result:number = Math.max.apply(Math, this.dataStore.qualipacRecords.map(o => { return o.pallet; })) as number;

    if(result) return result + 1;
    else return 1;
  }

  getPackhouseDate(): any {
    return this.dateFormatPipe.transform(new Date());
  }
}