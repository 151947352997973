import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

/*
WHAT IS THE PURPOSE OF THIS SERVICE??

This service is used in the packhouse aspect of the system
to route the packhouse form to the right step
depending on which of the routes has been activate
in the packhouse menu. 

It gets set in the packhouse component when the route 
is changed on click. The route change is checked to determine
which index the form should then flip to.

*/
@Injectable()
export class PackhouseService implements OnDestroy {

    palletIdObserver: Observable<string>;
    private _palletId: BehaviorSubject<string>;
    
    currentStepObserver: Observable<string>;
    private _currentStep: BehaviorSubject<string>;

    stepIndexObserver: Observable<number>;
    private _stepIndex: BehaviorSubject<number>;

    // given that the step changes based on the route path
    // which is text, when that chantes, 
    // listen for the change then update the index.
    currentStepSubscription: Subscription;

    private dataStore: {
        palletId: string,
        currentStep: string,
        stepIndex: number
    }

    constructor(){
        this.dataStore = {
            palletId: '',
            currentStep: '',
            stepIndex: 0
        }    

        this._palletId = <BehaviorSubject<string>>new BehaviorSubject('');
        this.palletIdObserver = this._palletId.asObservable();

        this._currentStep = <BehaviorSubject<string>>new BehaviorSubject('');
        this.currentStepObserver = this._currentStep.asObservable();
        
        this._stepIndex = <BehaviorSubject<number>>new BehaviorSubject(0);
        this.stepIndexObserver = this._stepIndex.asObservable();

        this.currentStepSubscription = this.currentStepObserver.subscribe(currentStep => {
            this.updateStepIndex(currentStep);
        });
    }

    updateStepIndex(currentStep: string){
        // these three routes correspond to three form steps
        // in this particular order
        const steps = ["receival", "packaging", "dispatch"];
        const index = steps.indexOf(currentStep.toLowerCase());
        if(index > -1) {
            this.setStepIndex(index);
        }
    }

    setPallet(palletId:string) {
        this.dataStore.palletId = palletId;
        this._palletId.next(Object.assign({}, this.dataStore).palletId);
    }

    setCurrentStep(currentStep: string){
        this.dataStore.currentStep = currentStep;
        this._currentStep.next(Object.assign({}, this.dataStore).currentStep);
    }

    setStepIndex(currentStepIndex: number) {
        this.dataStore.stepIndex = currentStepIndex;
        this._stepIndex.next(Object.assign({}, this.dataStore).stepIndex);
    }

    ngOnDestroy(){
        this.currentStepSubscription.unsubscribe();
    }
}