import { Component } from '@angular/core';

@Component({
    selector: 'qualipac-accounting',
    templateUrl: './accounting.component.html',
    styles: [`
      nb-card {
        transform: translate3d(0, 0, 0);
      }
    `],
  })

export class AccountingComponent {

}