import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/registration.component';
import { RegistrationConfirmationComponent } from './confirm/confirmRegistration.component';
import { ResendCodeComponent } from './resend/resendCode.component';
import { ForgotPassword2Component, ForgotPasswordStep1Component } from './forgot/forgotPassword.component';
import { NewPasswordComponent } from './newpassword/newpassword.component';
import { AuthComponent } from './auth.component';
import { NotFoundComponent } from '../@theme/miscellaneous/not-found/not-found.component';
import { MiscellaneousComponent } from '../@theme/miscellaneous/miscellaneous.component';
import { MiscellaneousModule } from '../@theme/miscellaneous/miscellaneous.module';

const routes: Routes = [{
  path: '',
  component: AuthComponent,
  children: [
    {path: 'login', component: LoginComponent},
      {path: 'register', component: RegisterComponent},
      {path: 'confirmRegistration/:username', component: RegistrationConfirmationComponent},
      {path: 'resendCode', component: ResendCodeComponent},
      {path: 'forgotPassword/:email', component: ForgotPassword2Component},
      {path: 'forgotPassword', component: ForgotPasswordStep1Component},
      {path: 'newPassword', component: NewPasswordComponent},
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  }, {
    path: '**',
    component: NotFoundComponent,
  }
],
}];

@NgModule({
  imports: [
      MiscellaneousModule,
      RouterModule.forChild(routes)
    ],
  exports: [RouterModule],
})

export class AuthRoutingModule {
}